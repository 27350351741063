export class UserRegistration {
  companyName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  password1: string;
  password2: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
