export const environment = {
  production: false,
  testing: true,
  apiUrl: 'https://tfcapi.millertechnologygroupllc.com',
  awsUrl: 'https://textilefabric.s3.amazonaws.com',
  swatchDomain: 'getyourswatchkit',
  swatchUrl: 'https://www.getyourswatchkit.com/categories/238',
  fromAddress: 'susanelliott@textilefabric.com',
  stripePublicKey: 'pk_live_0OU2DURJrYEu98P5NbQOp67B',
  stripePrivateKey: 'sk_live_eufJ7Qv4WBFGJIupxVL9YVKd',
  salesTaxRate: '9.75',
  tfcPostalCode: '37167',
  tfcStateCode: 'TN'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
