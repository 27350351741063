import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Product } from './../../../models/product.model';

import { CategoryService } from './../../../services/site/category.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  product: Product;

  constructor(private route: ActivatedRoute, private categoryService: CategoryService) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log('productId: ' + params.id);
      this.getProduct(params.id);
    });
  }

  getProduct(productId: number) {
    this.categoryService.getProduct(productId)
      .subscribe(data => {
        this.product = data;
        console.log('data: ' + JSON.stringify(data));
      });
  }



}
