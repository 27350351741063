<form [formGroup]="productForm" (ngSubmit)="submit()">
  <div class="card" style="width: 100%;">
    <div class="row no-gutters">

      <div class="col-sm-12 col-md-4 text-center">
        <div class="product-image">
          <img src="{{ imageSource }}/{{ product.imagePath }}" alt="Product Image" style="width: 90%;" />
        </div>
      </div>
      <div class="col-sm-12 col-md-5">
        <div class="card-body">
          <div class="card-title">
            {{ product.name }}
          </div>

          <div class="card-text">
            <p [innerHTML]="product.description"></p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 price-content">

        <div class="card-title text-right" *ngIf="product.unitPrice !== null">{{product.unitPrice | currency}}</div>
        <div class="product-stock" *ngIf="product.unitPrice !== null && product.activeFlag">In Stock</div>
        <div class="product-not-available" *ngIf="product.unitPrice === null">Not Available</div>
        <div class="col-sm-12 text-right" *ngIf="product.unitPrice !== null">
          <div *ngIf="product.activeFlag">
            <div class="d-flex">
              <input type="text" class="form-control mr-1" maxlength="4" formControlName="quantity" /><br />
              <button class="btn btn-primary" type="submit">Add to Cart</button>
            </div>
          </div>
          <div *ngIf="!product.activeFlag">
            <b>Not currently available</b>
          </div>
        </div>
      </div>

    </div>
  </div>
</form>
