
export class ConfirmBillTo {
  companyName: string;
  firstName: string;
  lastName: string;
  address1: string;
  address1apt: string;
  address2: string;
  fullAddress: string;
  city: string;
  stateCode: string;
  postalCode: string;
  countryCode: string;
  emailAddress: string;
  phoneNumber: string;
  aptNumber: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
