import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Order } from './../../../../models/order.model';
import { OrderStatus } from './../../../../models/order-status.model';
import { OrderUpdate } from './../../../../models/order-update.model';

import { AdminService } from './../../../../services/site/admin.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from './../../../../services/auth/token.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit, AfterViewInit {

  order: Order;
  statuses: OrderStatus[];
  display = false;
  orderForm: FormGroup;
  orderStatusId = 0;
  submitted = false;
  subTotal = 0;
  orderTotal = 0;

  constructor(
    private readonly adminService: AdminService,
    private readonly tokenService: TokenService,
    private readonly fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private readonly toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if (!this.tokenService.isAdmin()) {
      this.router.navigate(['/home']);
    }
  }

  ngAfterViewInit(): void {
    this.getOrderStatuses();
    this.route.params.subscribe(params => {
      console.log('id: ' + params.id);
      this.getOrder(params.id);
      this.orderForm = this.createOrderForm();
    });
  }

  get f() { return this.orderForm.controls; }

  createOrderForm() {
    return this.fb.group({
      orderStatusId: [0, Validators.required]
    });
  }

  setOrderForm() {
    this.orderForm.setValue({
      orderStatusId: this.order.orderStatus.id
    });
  }

  getOrderStatuses() {
    this.adminService.getOrderStatuses()
      .subscribe(data => {
        this.statuses = [];
        data.forEach(d => {
          const item = new OrderStatus({ id: d.id, name: d.name });
          this.statuses.push(item);
        });
        console.log('statuses: ' + JSON.stringify(data));
      }, err => {
        console.log('getOrderStatuses error: ' + err);
      });
  }

  getOrder(orderId: number) {
    this.adminService.getOrder(orderId)
      .subscribe(data => {
        this.order = data;
        this.order.billTo.address1 = this.order.billTo.address1.replace( ',', ' ');
        this.order.shipTo.address1 = this.order.shipTo.address1.replace( ',', ' ');
        console.log('order: ' + JSON.stringify(data));
        this.display = true;
        this.setOrderForm();
      });
  }

  submit() {
    this.submitted = true;

    if (this.orderForm.invalid) {
      this.toastr.error('Order status is required');
    }

    const update = new OrderUpdate({ orderId: this.order.orderID, statusId: this.f.orderStatusId.value });
    console.log('orderUpdate: ' + JSON.stringify(update));

    this.adminService.updateOrderStatus(update)
      .subscribe(data => {
        this.toastr.success('Successfully updated order status');
        this.router.navigate(['/admin/orders']);
      }, err => {
        this.toastr.error('Error: ' + err);
      });
  }

}
