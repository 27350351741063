
import { Product } from './../models/product.model';

export class CategoryProduct {
  id: number;
  name: string;
  products: Product[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
