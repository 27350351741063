<div class="dashboard">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center title-bar">
        <h2>Administrator Dashboard</h2>
      </div>
    </div>
    <div class="row">

      <div class="col-sm-12 col-md-3 pad-btm-20">
        <a routerLink="/admin/orders">
          <div class="card orders-card">
            <div class="card-body">
              <h3 class="card-title">Orders</h3>
              <p><strong>New Orders: </strong>{{ (orders$ | async)?.length }}</p>
            </div>
          </div>
        </a>
      </div>

      <div class="col-sm-12 col-md-3 pad-btm-20">
        <a routerLink="/admin/users">
          <div class="card users-card">
            <div class="card-body">
              <h3 class="card-title">Users</h3>
              <p><strong>Total Users: </strong>{{ (users$ | async)?.length }}</p>
            </div>
          </div>
        </a>
      </div>

      <div class="col-sm-12 col-md-3 pad-btm-20">
        <a routerLink="/admin/products">
          <div class="card products-card">
            <div class="card-body">
              <h3 class="card-title">Products</h3>
              <p><strong>Total Products: </strong>{{ (products$ | async)?.length }}</p>
            </div>
          </div>
        </a>
      </div>

      <div class="col-sm-12 col-md-3 pad-btm-20">
        <a routerLink="/admin/categories">
          <div class="card categories-card">
            <div class="card-body">
              <h3 class="card-title">Categories</h3>
              <p><strong>Total Categories: </strong>{{ (categories$ | async)?.length }}</p>
            </div>
          </div>
        </a>
      </div>

      <div class="col-sm-12 col-md-3 pad-btm-20">
        <a routerLink="/admin/media">
          <div class="card categories-card">
            <div class="card-body">
              <h3 class="card-title">Videos</h3>
              <p><strong>Total Videos: </strong>{{ (videos$ | async)?.length }}</p>
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>
</div>
