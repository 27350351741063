import { EmailAddress } from './email-address.model';

export class Notification {
  fromAddress: EmailAddress;
  toAddress: EmailAddress;
  subject: string;
  body: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
