
export class OrderStatus {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
