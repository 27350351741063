<div class="container video-container">
    <div class="row">
        <div class="col-sm-12 admin-instructions title-bar">
            <h3>Textile Videos</h3>
            <p>Below is a list of videos.  Click on the View button to view the selected video.</p>
        </div>
        <div class="col-sm-12 text-right">
          <button class="btn btn-primary" [routerLink]="['/admin/media', 0]"><i class="fa fa-plus"></i>&nbsp;Add Textile Video</button>
        </div>
        <div class="col-sm-12">
          <div class="content-area">
  
              <p-table [value]="medias" [tableStyle]="{ 'min-width': '50rem' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Video Name</th>
                        <th>YouTube Link</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-v>
                    <tr>
                      <td>{{ v.subject }}</td>
                      <td>
                        {{ v.mediaUrl }}&nbsp;
                        <div *ngIf="!v.displayFlag"><span class="badge badge-danger">Hidden</span></div>
                      </td>
                      <td><button class="btn btn-success" [routerLink]="['/admin/media',v.id]"><i class="fa fa-check"></i>&nbsp;View</button></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                  <div class="flex align-items-center justify-content-between">
                      In total there are {{medias ? medias.length : 0 }} videos.
                  </div>
              </ng-template>
            </p-table>

          </div>
        </div>
    </div>
  </div>
  