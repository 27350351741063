<div class="container">
  <div class="row justify-content-center" ng-If="!loading">
    <!-- <div class="col-sm-12 col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Billing Address</h5>
          <p>
            {{ finalOrder.billTo?.companyName }}<br />
            {{ finalOrder.billTo?.firstName}} {{ finalOrder.billTo?.lastName}}<br />
            {{ finalOrder.billTo?.address1 }} {{ finalOrder.billTo?.aptNumber}}<br/>
            {{ finalOrder.billTo?.address2 }}<br />
            {{ finalOrder.billTo?.city }}, {{ finalOrder.billTo?.stateCode}}  {{ finalOrder.billTo?.postalCode }} {{ finalOrder.billTo?.countryCode }}<br /><br />
            <b>Email: </b>{{ finalOrder.billTo?.emailAddress}}<br />
            <b>Phone: </b>{{ finalOrder.billTo?.phoneNumber }}<br />
          </p>
        </div>
      </div>
    </div> -->
    <div class="col-sm-12 col-md-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Shipping Address</h5>
          <p>
            {{ finalOrder.shipTo?.companyName }}<br />
            {{ finalOrder.shipTo?.firstName}} {{ finalOrder.shipTo?.lastName}}<br />
            {{ finalOrder.shipTo?.address1 }} {{ finalOrder.shipTo?.aptNumber}}<br/>
            {{ finalOrder.shipTo?.address2 }}<br />
            {{ finalOrder.shipTo?.city }}, {{ finalOrder.shipTo?.stateCode}}  {{ finalOrder.shipTo?.postalCode }} {{ finalOrder.shipTo?.countryCode }}<br /><br />
            <b>Email: </b>{{ finalOrder.shipTo?.emailAddress}}<br />
            <b>Phone: </b>{{ finalOrder.shipTo?.phoneNumber }}<br />
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Order Details</h5>
          <b>Order Date: </b>{{ finalOrder.orderDetails?.orderDate | date:'MM/dd/yyyy' }}<br /><br />
          <b>Destination Type: </b>{{ finalOrder.orderDetails?.destinationType }}<br />
          <b>Shipping Carrier: </b>{{ finalOrder.orderDetails?.shippingCarrier }}<br />
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-striped table-bordered" ng-If="showTotal">
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of finalOrder?.lineItems">
                <td>{{ c.name }}</td>
                <td>{{ c.quantity }}</td>
                <td>{{ c.unitPrice | currency }}</td>
                <td>{{ c.itemTotal | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <table>
            <tbody>
            <tr>
              <td width="75%">
                <b>Shipping Notes</b><br/>
              Tracking information is sent to the email address you provide on the order form. Please check your spam if it is not in your inbox. 
              Contact Susan Elliott at <a mailto="susanelliott@textilefabric.com"><u>susanelliott&#64;textilefabric.com</u></a> for help, if you do not receive your kit.
                <br/><br/>
              KITS SHIPPED TO MAIL ROOMS, APARTMENTS, DORM ROOMS, ETC. ARE THE RESPONSIBILITY OF THE STUDENT TO LOCATE ONCE DELIVERED BY THE US POSTAL SERVICE.
              <br/><br/>
              Orders are shipped within 24-48 business hours through USPS Priority Mail. If you have not received your order within 10 business days, please contact us immediately. Failure to contact us within this timeframe may result in the need to purchase another kit using a different shipping address.
              <br/><br/>
              If shipping is not calculated or charged at checkout, you will be contacted and an additional payment of the correct shipping amount (based on your address) 
              will be required before your kit is shipped.<br/><br/>
              </td>
              <td width="25%" class="text-right">
                <div *ngIf="showTotal">
                  <b>Subtotal: </b> {{ finalOrder?.subTotal | currency }}<br />
                  <b>Shipping: </b> {{ finalOrder?.shippingTotal | currency }}<br />
                  <b>Tax: </b> {{ finalOrder?.salesTax | currency }}<br />
                  ----------------------------------<br />
                  <b>Order Total: </b> {{ finalOrder.orderTotal | currency }}
                </div>
                <!-- <div style="display: inline; padding: 20px; float: right;" *ngIf="showTotal"> -->
                <div class="text-right">
                  <br/>
                  <button class="btn btn-primary" routerLink="/orders/shipTo"><< Prev</button>&nbsp;&nbsp;
                  <app-stripe-payment [sessionOrder]="finalOrder"></app-stripe-payment>
                </div>
                <div class="text-right" *ngIf="!showTotal">
                  Calculating order total...
                </div>
              </td>
            </tr>
          </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <br/><br/>
</div>
