<div class="container" *ngIf="display">
  <form [formGroup]="orderForm" (ngSubmit)="submit()">
  <div class="row">

    <div class="col-sm-12 col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Billing Address</h5>
          <p>
            {{ order.billTo.companyName }}<br />
            {{ order.billTo.firstName}} {{ order.billTo.lastName}}<br />
            {{ order.billTo.address1 }} {{ order.billTo.aptNumber }}<br />
            {{ order.billTo.address2 }}<br />
            {{ order.billTo.city }}, {{ order.billTo.stateCode}}  {{ order.billTo.postalCode }} {{ order.billTo.countryCode }}<br /><br />
            <b>Email: </b>{{ order.billTo.emailAddress}}<br />
            <b>Phone: </b>{{ order.billTo.phoneNumber }}<br />
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Shipping Address</h5>
          <p>
            {{ order.shipTo.companyName }}<br />
            {{ order.shipTo.firstName}} {{ order.shipTo.lastName}}<br />
            {{ order.shipTo.address1 }} {{ order.shipTo.aptNumber }}<br />
            {{ order.shipTo.address2 }}<br />
            {{ order.shipTo.city }}, {{ order.shipTo.stateCode}}  {{ order.shipTo.postalCode }} {{ order.shipTo.countryCode }}<br /><br />
            <b>Email: </b>{{ order.shipTo.emailAddress}}<br />
            <b>Phone: </b>{{ order.shipTo.phoneNumber }}<br />
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Order Details</h5>
          <b>Order # </b>{{ order.orderID }}<br />
          <b>Order Date: </b>{{ order.dateCreated | date:'MM/dd/yyyy' }}<br /><br />
          <b>Destination Type: </b> Residential<br />
          <b>Shipping Carrier: </b> USPS - Priority Mail<br />
          <b>Order Status: </b>
          <select class="form-control" formControlName="orderStatusId">
            <option value="null" selected disabled>--Select Status--</option>
            <option *ngFor="let s of statuses" [ngValue]="s.id">{{ s.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of order.lineItems">
                <td>{{ c.productName }}</td>
                <td>{{ c.quantity }}</td>
                <td>{{ c.unitPrice | currency }}</td>
                <td>{{ c.itemPrice | currency }}</td>
              </tr>
            </tbody>
          </table>




          <div class="text-right">
            <b>Subtotal: </b> {{ order.subTotal| currency }}<br />
            <b>Shipping: </b> {{ order.shippingAmount | currency }}<br />
            <b>Tax: </b> {{ order.salesTax | currency }}<br />
            ----------------------------------<br />
            <b>Order Total: </b> {{ order.orderTotal| currency }}
          </div>

          <div style="display: inline; padding: 20px; float: right;">
            <button class="btn btn-primary" type="submit"><i class="fa fa-check"></i> Save Order</button>&nbsp;&nbsp;
          </div>

          <div class="row">
            <div class="text-left">
              <h5>Stripe Transaction Data</h5>
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Transaction Date</th>
                    <th>Amount</th>
                    <th>Captured</th>
                    <th>Failure Code</th>
                    <th>Failure Message</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let t of order.transactions">
                    <td>{{ t.id }}</td>
                    <td>{{ t.createdAt | date: 'MM/dd/yyyy' }}</td>
                    <td>{{ t.transactionAmount }}</td>
                    <td>{{ t.captured }}</td>
                    <td>{{ t.failureCode }}</td>
                    <td>{{ t.failureMessage }}</td>
                  </tr>
                  <tr *ngIf="order.transactions.length === 0">
                    <td colspan="6">
                      No transactions found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</div>
