import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminOrder } from '../../../models/admin-order.model';

import { AdminService } from './../../../services/site/admin.service';
import { CategoryService } from './../../../services/site/category.service';
import { TokenService } from './../../../services/auth/token.service';
import { Observable, Subscription, timer } from 'rxjs';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { map } from 'rxjs/operators';

export interface CombinedStatus
{
  id: number;
  name: string;
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {

  orders: AdminOrder[] = [];
  statuses: CombinedStatus[] = [];
  selectedStatus  = 'Ordered';
  displayOrders = false;
  // timerSubscription: Subscription;

  constructor(
    private readonly adminService: AdminService,
    private readonly categoryService: CategoryService,
    private readonly tokenService: TokenService,
    private readonly loadingService: LoadingService,
    private router: Router
  ) { }

  ngOnInit(): void {

    if (this.canViewAdmin()) {
      this.getOrderStatuses();
      this.getOrders(this.selectedStatus);

      // timer(0, 10000) call the function immediately and every 10 seconds
      // this.timerSubscription = timer(0, 10000).pipe(
      //   map(() => {
      //     this.getOrders(this.selectedStatus);
      //   })
      // ).subscribe();
    }
    else {
      this.router.navigate(['/home']);
    }
  }

  ngOnDestroy(): void {
    // this.timerSubscription.unsubscribe();
    console.log('admin/order onDestroy');
  }

  canViewAdmin() {
    return this.tokenService.isAdmin();
  }

  getOrders(statusCode: string) {
    this.adminService.getOrders(statusCode)
      .subscribe(data => {
        this.orders = data;
      });

    this.displayOrders = true;
  }

  getOrderStatuses() {
    console.log("getOrderStatuses");
    this.adminService.getOrderStatuses()
      .subscribe(data => {
        console.log('getOrderStatuses: ' + JSON.stringify(data));
        this.statuses = data;
      });
  }

  selectOrderStatus(newValue) {
    this.selectedStatus = newValue;
    this.getOrders(this.selectedStatus);
  }

}
