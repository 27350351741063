import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';

import { environment } from './../../../../../environments/environment';

import { AdminCategory } from './../../../../models/admin-category.model';
import { AdminCategoryUpdate } from './../../../../models/admin-category-update.model';
import { CategoryUpdate } from './../../../../models/category-update.model';
import { AdminService } from './../../../../services/site/admin.service';
import { CategoryService } from './../../../../services/site/category.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from './../../../../services/auth/token.service';

import { NgxSpinnerService } from "ngx-spinner";

export class FileInfo
{
  fileName: string;
  fileType: string;
  fileData: string;
}

@Component({
  selector: 'app-admin-category-detail',
  templateUrl: './admin-category-detail.component.html',
  styleUrls: ['./admin-category-detail.component.scss']
})
export class AdminCategoryDetailComponent implements OnInit {

  parentCategory: AdminCategory;
  subCategory: AdminCategory;
  newCategory: CategoryUpdate;
  parentForm: FormGroup;
  categoryForm: FormGroup;
  uploadFile = new FileInfo();
  submitted = false;
  showCategory = false;
  //imageSource = `/assets/images`;
  imageSource = `${environment.awsUrl}/categories`;
  imagePath: String = "";
  categoryName: String = "New Category";
  selectedFile: File | null = null;
  uploadProgress: number | null = null;
  uploadMessage: string | null = null;

  constructor(
    private readonly adminService: AdminService,
    private readonly categoryService: CategoryService,
    private readonly toastr: ToastrService,
    private readonly tokenService: TokenService,
    private readonly fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if (!this.tokenService.isAdmin()) {
      this.router.navigate(['/home']);
    }
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
    this.parentForm = this.createParentForm();
    this.route.params.subscribe(params => {
      console.log('id: ' + params.id);
      this.getCategory(params.id);
      this.parentForm = this.createParentForm();
    });
  }

  get f() { return this.parentForm.controls; }
  get sf() { return this.categoryForm.controls; }

  createNewCategory() {
    this.newCategory = new CategoryUpdate
    ({
      id: 0,
      parentID: this.parentCategory.id,
      name: '',
      displayOrder: 0,
      imagePath: '',
      activeFlag: true
    });
  }

  createParentForm() {
    return this.fb.group({
      name: ['', Validators.required],
      displayOrder: [0],
      activeFlag: [true],
      imagePath: [''],
      file: ['']
    });
  }

  createCategoryForm(category) {
    return this.fb.group({
      name: [category.name, Validators.required],
      displayOrder: [category.displayOrder],
      activeFlag: [category.activeFlag],
      imagePath: [category.imagePath]
    });
  }

  getCategory(id: number) {
    this.categoryService.getCategory(id)
      .subscribe(category => {
        console.log('parent: ' + JSON.stringify(category));
        this.parentCategory = category;
        this.setParentForm(this.parentCategory);
        this.createNewCategory();
        let maxDisplayOrder = this.getMaxDisplayOrder(this.parentCategory);
        this.newCategory.displayOrder = maxDisplayOrder;
        this.showCategory = true;
      });
  }

  setParentForm(category: AdminCategory) {
    this.parentForm.setValue({
      name: category.name,
      displayOrder: category.displayOrder,
      activeFlag: category.activeFlag,
      imagePath: category.imagePath,
      file: ''
    });
    this.imagePath = category.imagePath;
    this.categoryName = category.name;
  }

  createCategoryUpdate() {
    return new AdminCategory({
      id: this.parentCategory.id,
      name: this.f.name.value,
      parentID: null,
      displayOrder: this.f.displayOrder.value,
      activeFlag: this.f.activeFlag.value,
      imagePath: this.uploadFile.fileName === undefined || this.uploadFile.fileName === null ?
        this.f.imagePath.value :
        this.uploadFile.fileName,
      imageFileType: this.uploadFile.fileType,
      imageFileData: this.uploadFile.fileData,
    });
  }

  onUpload(): void {
    console.log('onUpload called');
    if (!this.selectedFile) {
      this.uploadMessage = 'No file selected!';
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);

    this.http.post('assets/images/categories', formData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(event => {
      console.log('event: ' + JSON.stringify(event));
      if (event.type === HttpEventType.UploadProgress) {
        this.uploadProgress = Math.round((100 * event.loaded) / (event.total || 1));
      } else if (event.type === HttpEventType.Response) {
        this.uploadMessage = 'Upload successful!';
      }
      console.log('msg: ' + this.uploadMessage);
      this.f.imagePath.setValue(this.selectedFile.name);
      
    }, error => {
      this.uploadMessage = 'Upload failed!';
      console.log('msg: ' + error.message);
    });
  }

  submit() {
    console.log('called submit');
    this.submitted = true;

    if (this.parentForm.invalid) {
      return;
    }

    this.spinner.show();
    const categoryUpdate = this.createCategoryUpdate();
    console.log('categoryUpdate: ' + JSON.stringify(categoryUpdate));

    this.adminService.saveCategory(categoryUpdate)
      .subscribe(data => {
        //this.parentCategory = data;
        this.toastr.success('Category successfully updated');
        this.getCategory(categoryUpdate.id);
        this.parentForm = this.createParentForm();
        this.spinner.hide();
      },
    error => {
      this.spinner.hide();
      console.log('Admin Category Error: ' + error);
      this.toastr.error('Error updating category: ' + error.message);
    });
  }

  toggleEdit(category: AdminCategory) {
    category.isEditMode = !category.isEditMode;

    if (category.isEditMode) {
      this.subCategory = category;
      this.categoryForm = this.createCategoryForm(category);
    }
  }

  updateCategory() {
    console.log('updateCategory..');
    const categoryUpdate = new CategoryUpdate({
      id: Number(this.subCategory.id),
      name: this.sf.name.value,
      imagePath: this.uploadFile.fileName === undefined || this.uploadFile.fileName === null ?
        this.sf.imagePath :
        this.uploadFile.fileName,
      imageFileType: this.uploadFile.fileType,
      imageFileData: this.uploadFile.fileData,
      displayOrder: Number(this.sf.displayOrder.value),
      parentID: Number(this.parentCategory.id),
      activeFlag: this.sf.activeFlag.value
    });

    this.subCategory.name = this.sf.name.value;
    this.subCategory.displayOrder = Number(this.sf.displayOrder.value);
    this.subCategory.activeFlag = this.sf.activeFlag.value;

    this.parentCategory.children.forEach(c => {
      if (c.id === this.subCategory.id) {
        c.name = this.subCategory.name;
        c.displayOrder = this.subCategory.displayOrder;
        c.activeFlag = this.subCategory.activeFlag;
        this.toggleEdit(c);
      }
    });

    console.log('updateCategory: ' + JSON.stringify(categoryUpdate));

    this.categoryService.updateCategory(categoryUpdate)
      .subscribe(data => {
        this.toastr.success('Successfully updated category');
        this.getCategory(this.parentCategory.id);
      });
  }

  saveNewCategory() {
    const adminCategory = new AdminCategory(
      {
        id: 0,
        parentID: Number(this.parentCategory.id),
        name: this.newCategory.name,
        imagePath: '',
        displayOrder: Number(this.newCategory.displayOrder),
        activeFlag: this.newCategory.activeFlag,
        isEditMode: true,
        children: []
      }
    );
    console.log('newCategory: ' + JSON.stringify(adminCategory));

    this.categoryService.createCategory(adminCategory)
      .subscribe(data => {
        this.toastr.success('Successfully saved new category');
        this.getCategory(this.parentCategory.id);
      });
  }

  getMaxDisplayOrder(parent: AdminCategory) {
    let maxDisplayOrder = 0;
    for(var i = 0; i < parent.children.length; i++) {
      console.log("data: " + JSON.stringify(parent.children[i]));
      
      if (parent.children[i] !== undefined) {
        console.log('do: ' + parent.children[i].displayOrder);
        if (maxDisplayOrder < parent.children[i].displayOrder) {
          maxDisplayOrder = parent.children[i].displayOrder;
        }
      }
    }
    maxDisplayOrder += 1;
    return maxDisplayOrder;
  }

  onFileChange(files: FileList) {

    if (files.length > 0) {
      const file = files[0];
      const fileToUpload = file.name;

      this.uploadFile.fileName = file.name;
      this.uploadFile.fileType = file.type;

      this.getBase64(file);
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
    }
  }

  
  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadFile.fileData = reader.result.toString();
    };
    reader.onerror = error => {
      console.log('Error: ', error);
      return null;
    };
  }

}
