
export class ConfirmOrderDetails {
  orderId: number;
  orderDate: Date;
  destinationType: string;
  shippingCarrier: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
