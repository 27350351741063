<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card-content" style="min-height: 750px">
      <app-product [product]="product"></app-product>
    </div>
    </div>
  </div>
</div>

