
export class AdminAccount {
  accountId: number;
  firstName: string;
  lastName: string;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountryCode: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingPostalCode: string;
  mailingCountryCode: string;
  companyName: string;
  billingPhoneNumber: string;
  mailingPhoneNumber: string;
  activeFlag: boolean;
  createdAt: Date;
  updatedAt: Date;
  stripeCustomerToken: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
