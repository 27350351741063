import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminAccount } from './../../../../models/admin-account.model';
import { AdminOrder } from '../../../../models/admin-order.model';

import { AdminService } from './../../../../services/site/admin.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from './../../../../services/auth/token.service';
import { UserService } from './../../../../services/site/user.service';

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  userId: number;
  account: AdminAccount;
  orders: AdminOrder[] = [];
  accountForm: FormGroup;
  submitted = false;

  constructor(
    private readonly adminService: AdminService,
    private readonly fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly tokenService: TokenService,
    private readonly userService: UserService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if (!this.tokenService.isAdmin()) {
      this.router.navigate(['/home']);
    }
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
    this.route.params.subscribe(params => {
      this.userId = params.id;
      this.accountForm = this.createAccountForm();
      console.log('userId: ' + this.userId);
      this.getUserAccount(this.userId);
    });
  }

  get f() { return this.accountForm.controls; }

  getUserAccount(userId: number) {
    this.adminService.getUserAccount(userId)
      .subscribe(data => {
        this.account = data;
        console.log('user: ' + JSON.stringify(data));
        this.setAccountForm(this.account);
        this.getUserOrders(userId);
      });
  }

  getUserOrders(userId: number) {
    this.adminService.getUserOrders(userId)
      .subscribe(data => {
        this.orders = data;
        console.log('orders: ' + JSON.stringify(data));
      });
  }

  createAccountForm() {
    return this.fb.group({
      firstName: [''],
      lastName: ['', Validators.required],
      companyName: [''],
      billingAddress1: ['', Validators.required],
      billingAddress2: [''],
      billingCity: ['', Validators.required],
      billingState: ['', Validators.required],
      billingPostalCode: [''],
      billingCountryCode: ['', Validators.required],
      mailingAddress1: ['', Validators.required],
      mailingAddress2: [''],
      mailingCity: ['', Validators.required],
      mailingState: ['', Validators.required],
      mailingPostalCode: [''],
      mailingCountryCode: ['', Validators.required],
      billingPhoneNumber: [''],
      mailingPhoneNumber: [''],
      activeFlag: [false]
    });
  }

  setAccountForm(account: AdminAccount) {
    this.accountForm.setValue({
      firstName: account.firstName,
      lastName: account.lastName,
      companyName: account.companyName,
      billingAddress1: account.billingAddress1,
      billingAddress2: account.billingAddress2,
      billingCity: account.billingCity,
      billingState: account.billingState,
      billingPostalCode: account.billingPostalCode,
      billingCountryCode: account.billingCountryCode,
      mailingAddress1: account.mailingAddress1,
      mailingAddress2: account.mailingAddress2,
      mailingCity: account.mailingCity,
      mailingState: account.mailingState,
      mailingPostalCode: account.mailingPostalCode,
      mailingCountryCode: account.mailingCountryCode,
      billingPhoneNumber: account.billingPhoneNumber,
      mailingPhoneNumber: account.mailingPhoneNumber,
      activeFlag: account.activeFlag
    });
  }

  submit() {
    this.submitted = true;

    if (this.accountForm.invalid) {
      this.toastr.error('All required fields are not completed');
      return;
    }

    this.spinner.show();
    const account = this.createAccount();
    console.log('account: ' + JSON.stringify(account));
    this.adminService.updateUserAccount(account)
      .subscribe(data => {
        this.toastr.success('User account has been successfully updated');
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        console.log('Admin User Error: ' + error.message);
        this.toastr.error('Error updating user account: ' + error.message);
      });

  }

  createAccount() {
    return new AdminAccount({
      accountId: Number(this.userId),
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      billingAddress1: this.f.billingAddress1.value,
      billingAddress2: this.f.billingAddress2.value,
      billingCity: this.f.billingCity.value,
      billingState: this.f.billingState.value,
      billingPostalCode: this.f.billingPostalCode.value,
      billingCountryCode: this.f.billingCountryCode.value,
      mailingAddress1: this.f.mailingAddress1.value,
      mailingAddress2: this.f.mailingAddress2.value,
      mailingCity: this.f.mailingCity.value,
      mailingState: this.f.mailingState.value,
      mailingPostalCode: this.f.mailingPostalCode.value,
      mailingCountryCode: this.f.mailingCountryCode.value,
      companyName: this.f.companyName.value,
      billingPhoneNumber: this.f.billingPhoneNumber.value,
      mailingPhoneNumber: this.f.mailingPhoneNumber.value,
      activeFlag: this.f.activeFlag.value
    });
  }

}
