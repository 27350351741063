<div class="container">
  <div class="row">
    <div class="col-sm-12">

      <div class="card-content-area">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <h5 class="card-title">Complimentary Instructor Materials</h5>
                  </div>
                  <div class="col-sm-12 col-md-3 text-center">
                    <img src="/assets/images/textile-def.png" />
                  </div>
                  <div class="col-sm-12 col-md-1">&nbsp;</div>
                  <div class="col-sm-12 col-md-8">

                    <ul>
                      <li>Complimentary preview kits are available upon request</li>
                      <li>Upon adoption of any of our swatch kits, instructors receive the following materials:</li>
                      <ul>
                        <li>Fabric Swatch Kit</li>
                        <li>Fiber Samples Packet</li>
                        <li>Detailed Instructor Manual (digital and hard copy)</li>
                        <li>PowerPoint Presentation (fiber to finish discussion of textiles that includes references to swatches)</li>
                        <li>Images of mounted swatches</li>
                        <li>Images of completed mounting sheets (see below for new option)</li>
                        <li>Laboratory manual for textile testing</li>
                        <li>Questions and answers (currently available with Basic and Consumer Swatch Kits)</li>
                        <li>Textile video links</li>
                        <li>TFC Textile Education Videos (under development)</li>
                      </ul>
                    </ul>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
<!--
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">New For Instructors</h5>
                <p>
                  TFC now offers complimentary filled-in mounting sheets for instructors.  If you would like to receive a
                  hard copy of the completed mounting sheets with your next instructor kit, please let us know.
                </p>
              </div>
            </div>
          </div>
        </div>
-->
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Customized Lab Kits</h5>
                <div class="row">
                  <div class="col-sm-12 col-md-3 text-center">
                    <img src="/assets/images/tests_iposter2.jpg" />
                  </div>
                  <div class="col-sm-12 col-md-1">&nbsp;</div>
                  <div class=" col-sm-12 col-md-8">
                    <p>
                      We now offer customized lab kits:
                    </p>
                      <ul>
                        <li>Excellent resource to allow students to get all supplies in one product</li>
                        <li>Can be created to use remotely or in class</li>
                        <li>Instructors provide details of what they want included and we provide a quote</li>
                        <li>Available for bookstore or direct-to-student purchasing</li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Hybrid Swatch Kits</h5>
                <div class="row">

                    <div class="col-sm-12 col-md-8">
                      <p>
                        TFC understands that teaching textile courses effectively requires much preparation.  A
                        textiles course with fashion and interior design majors creates an even greater challenge.
                        The hybrid swatch kit bundles ensure that interior design and fashion majors are given the
                        opportunity to learn about fabrics in their field of study.  Please contact us at Susan Elliott (susan.elliott&#64;textilefabric.com)
                        for bundle options and pricing.
                      </p>
                    </div>
                    <div class="col-sm-12 col-md-3 text-center">
                      <img src="/assets/images/fashion_id_hybrid.jpg" />
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Swatch Kit Add-Ons</h5>
                <div class="row">
                  <div class="col-sm-12 col-md-3 text-center">
                    <img src="/assets/images/add_ons1.jpg" />
                  </div>
                  <div class="col-sm-12 col-md-1">&nbsp;</div>
                  <div class="col-sm-12 col-md-8">
                    <p>
                      Customize the kit of your choice by adding one or more supplemental items:
                    </p>
                      <ul>
                        <li>Fiber Samples Packet</li>
                        <li>Yarn Samples Packet</li>
                        <li>Trim Kit</li>
                        <li>Narrows Kit</li>
                        <li>LED Stitch Counter</li>
                        <li>Fashion Designers Textile Directory</li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Kit Modifications</h5>
                <p>
                  The swatch kits can be modified in the following ways to fit the needs of your textiles course.
                </p>
                <ul>
                  <li>Removing the Master List</li>
                  <ul>
                    <li>TFC ships the kits without the master lists to challenge the students to learn to identify the fabrics as they learn the textile concepts.</li>
                  </ul>
                  <li>Sharing answers with students</li>
                  <ul>
                    <li>For courses with limited time for in-depth study of textiles, instructors may choose to give students access to all available swatch information</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <p class="text-center instructions">
          Please contact us at <a href="mailto:susan@getyourswatchkit.com">Susan Elliott</a> to receive a
          wholesale price and more information on any of the above options.<br />
        </p>
      </div> <!-- end card content area -->
    </div>
  </div>
</div>
