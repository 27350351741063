<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">40 Year Celebration</h5>
          <p class="card-text">
            Textile Fabric Consultants, Inc. is excited to be celebrating 40 years in textile education. We appreciate
            our customers and would like to say thank you by sending you a free product. To take advantage of this
            offer, please complete the form below and select a free product then click “Place Order”. This
            offer is only for instructors, so please include your university address as the billing address.<br/><br/>
          </p>
          <form [formGroup]="requestForm" (ngSubmit)="submit()">
            <div class="row">
              <div class="col-md-6">
                <h5>Billing Information</h5>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter college or university name" formControlName="bCompanyName" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter first name" formControlName="bFirstName" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter last name" formControlName="bLastName" />
                  <div *ngIf="f.bLastName.touched && f.bLastName.invalid" class="alert alert-danger">
                    <div *ngIf="f.bLastName.errors.required">Last Name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter address line #1" formControlName="bAddress1" />
                  <div *ngIf="f.bAddress1.touched && f.bAddress1.invalid" class="alert alert-danger">
                    <div *ngIf="f.bAddress1.errors.required">Address Line #1 is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter address line #2" formControlName="bAddress2" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter city" formControlName="bCity" />
                  <div *ngIf="f.bCity.touched && f.bCity.invalid" class="alert alert-danger">
                    <div *ngIf="f.bCity.errors.required">City is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <select class="form-control" formControlName="bStateCode">
                    <option value="null" selected disabled>--Select State--</option>
                    <option *ngFor="let state of (states$ | async)" [ngValue]="state.stateCode">{{ state.name }}</option>
                  </select>
                  <div *ngIf="f.bStateCode.touched && f.bStateCode.invalid" class="alert alert-danger">
                    <div *ngIf="f.bStateCode.errors.required">State is required.</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter postal code" formControlName="bPostalCode" />
                  <div *ngIf="f.bPostalCode.touched && f.bPostalCode.invalid" class="alert alert-danger">
                    <div *ngIf="f.bPostalCode.errors.required">Postal Code is required</div>
                    <div *ngIf="f.bPostalCode.errors.maxlength">Postal Code cannot be longer than 5 characters.</div>
                    <div *ngIf="f.bPostalCode.errors.minlength">Postal Code must be 5 characters.</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter email address" formControlName="bEmailAddress" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter phone number" formControlName="bPhoneNumber" />
                </div>
              </div>
              <div class="col-md-6">
                <h5>Shipping Information </h5>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter college or university name" formControlName="sCompanyName" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter first name" formControlName="sFirstName" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter last name" formControlName="sLastName" />
                  <div *ngIf="f.sLastName.touched && f.sLastName.invalid" class="alert alert-danger">
                    <div *ngIf="f.sLastName.errors.required">Last Name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter address line #1" formControlName="sAddress1" />
                  <div *ngIf="f.sAddress1.touched && f.sAddress1.invalid" class="alert alert-danger">
                    <div *ngIf="f.sAddress1.errors.required">Address Line #1 is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter address line #2" formControlName="sAddress2" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter city" formControlName="sCity" />
                  <div *ngIf="f.sCity.touched && f.sCity.invalid" class="alert alert-danger">
                    <div *ngIf="f.sCity.errors.required">City is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <select class="form-control" formControlName="sStateCode">
                    <option value="null" selected disabled>--Select State--</option>
                    <option *ngFor="let state of (states$ | async)" [ngValue]="state.stateCode">{{ state.name }}</option>
                  </select>
                  <div *ngIf="f.sStateCode.touched && f.sStateCode.invalid" class="alert alert-danger">
                    <div *ngIf="f.sStateCode.errors.required">State is required.</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter postal code" formControlName="sPostalCode" />
                  <div *ngIf="f.sPostalCode.touched && f.sPostalCode.invalid" class="alert alert-danger">
                    <div *ngIf="f.sPostalCode.errors.required">Postal Code is required</div>
                    <div *ngIf="f.sPostalCode.errors.maxlength">Postal Code cannot be longer than 5 characters.</div>
                    <div *ngIf="f.sPostalCode.errors.minlength">Postal Code must be 5 characters.</div>
                  </div>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter email address" formControlName="sEmailAddress" />
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Enter phone number" formControlName="sPhoneNumber" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <br/><Br/>
                  <label>Select Free Product:</label>
                  <select class="form-control" formControlName="selectedKit">
                    <option value="null" selected disabled>--Select Kit--</option>
                    <option *ngFor="let kit of kits" [ngValue]="kit.id">{{ kit.name }}</option>
                  </select>
                  <div *ngIf="f.selectedKit.touched && f.selectedKit.invalid" class="alert alert-danger">
                    <div *ngIf="f.selectedKit.errors.required">Kit Selection is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <button class="btn btn-primary" type="submit" [disabled]="requestForm.invalid"><i class="fa fa-check"></i>&nbsp;Place Order</button>
              </div>
            </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</div>
