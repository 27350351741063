import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mounted-swatches',
  templateUrl: './mounted-swatches.component.html',
  styleUrls: ['./mounted-swatches.component.scss']
})
export class MountedSwatchesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log('mounted-swatches init');
  }

}
