import { Component } from '@angular/core';

@Component({
  selector: 'app-student-resources',
  templateUrl: './student-resources.component.html',
  styleUrls: ['./student-resources.component.scss']
})
export class StudentResourcesComponent {

}
