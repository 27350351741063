import { ConfirmBillTo } from './confirm-bill-to.model';
import { ConfirmShipTo } from './confirm-ship-to.model';
import { ConfirmOrderDetails } from './confirm-order-details.model';
import { CartItem } from './cart-item.model';

export class ConfirmOrder {
  billTo: ConfirmBillTo;
  shipTo: ConfirmShipTo;
  orderDetails: ConfirmOrderDetails;
  lineItems: CartItem[];
  subTotal: number;
  salesTax: number;
  shippingTotal: number;
  orderTotal: number;
  userId: number;
  accountId: number;
  identifier: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
