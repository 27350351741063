<div class="container" *ngIf="showProduct">

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="row">
          <div class="col">
            <h4 class="card-title">Product Information</h4>
          </div>
        </div>
        <form [formGroup]="productForm">
          <div class="row">
            <div class="col" *ngIf="product.id > 0">
              <img src="{{ imageSource }}/{{product.imagePath }}" alt="Product Image" style="width: 70%;" />
              <div class="upload-data">
                <input type="file" formControlName="file" (change)="onFileChange($event.target.files)" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Product Name</label>
                <input type="text" class="form-control" formControlName="name" placeholder="Enter product name" />
              </div>
              <div class="form-group">
                <label>Description</label>
                <!-- <ngx-mat-markdown-text-editor formControlName="description" appearance="outline" matAutosize [livePreviewEnabled]="true"
                    rows="5" (change)="change($event)"></ngx-mat-markdown-text-editor> -->
                <textarea class="form-control" formControlName="description" placeholder="Enter product description" rows="5"></textarea>
              </div>
              <div class="form-group">
                <label>Category/Subcategory</label>
                <select formControlName="categoryId" class="form-control">
                  <option *ngIf="product.productCategoryID === 0" [value]="0">--Select One--</option>
                  <option *ngFor="let c of (categories$ | async)" [value]="c.id">{{ c.name }}</option>
                </select>
              </div>
              <div class="form-group">
                <label>Weight (lbs)</label>
                <input type="text" class="form-control" formControlName="weight" placeholder="Enter product weight" />
              </div>
              <div class="form-group">
                <label>Price Label</label>
                <input type="text" class="form-control" formControlName="priceLabel" placeholder="Enter price label" />
              </div>
              <div class="input-group">
                <label>Unit Price&nbsp;&nbsp;</label>
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control currency" formControlName="unitPrice" placeholder="Enter unit price" />
              </div>
              <div class="form-group row">
                <div class="col">
                  <label>
                  <input type="checkbox" formControlName="activeFlag" />&nbsp;&nbsp;Active</label>
                </div>
              </div>
              <div class="form-group row">
                <div class="col">
                  <label>
                    <input type="checkbox" formControlName="noShippingFlag" /> No Shipping Flag</label>
                </div>
              </div>
              <div class="input-group">
                <label>Shipping Amount&nbsp;&nbsp;</label>
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control currency" formControlName="shippingAmount" placeholder="Enter shipping amount" />
              </div>

              <div class="d-flex">
                <div class="p-2 flex-fill text-right">
                  <button type="submit" class="btn btn-primary" (click)="submit()"><i class="fa fa-check"></i>&nbsp;Save Record</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>