import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

import { ErrorService } from '../utility/error.service';
import { LoggerService } from '../utility/logger.service';

export abstract class RestService {

  protected baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    private readonly error: ErrorService

  ) {
    this.baseUrl = environment.apiUrl;
  }

  protected get<T>(relativeUrl: string): Observable<any> {

    return this.httpClient.get<T>(this.baseUrl + relativeUrl)
      .pipe(
        catchError(this.error.handleError)
      );
  }

  protected post<T>(relativeUrl: string, data: any) {

    const body = JSON.stringify(data);
    console.log('post-body: ' + body);
    return this.httpClient.post<T>(this.baseUrl + relativeUrl, body)
      .pipe(
        catchError(this.error.handleError)
      );
  }

  protected put<T>(relativeUrl: string, data: any) {

    const body = JSON.stringify(data);
    console.log('body: ' + body);
    console.log('url: ' + this.baseUrl + relativeUrl);

    return this.httpClient.put<T>(this.baseUrl + relativeUrl, body)
      .pipe(
        catchError(this.error.handleError)
      );
  }

  protected delete<T>(relativeUrl: string) {

    return this.httpClient.delete<T>(this.baseUrl + relativeUrl)
      .pipe(
        catchError(this.error.handleError)
      );
  }

}
