
export class Product {
  id: number;
  productCategoryID: number;
  name: string;
  description: string;
  imagePath: string;
  activeFlag: boolean;
  weight: number;
  alternateName: string;
  noShippingFlag: boolean;
  shippingAmount: number;
  createdAt: Date;
  updatedAt: Date;
  priceLabel: string;
  unitPrice: number;
  featuredFlag: boolean;
  imageFileData: string;
  imageType: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
