<div class="container">
  <div class="col-sm-12">
    <div *ngIf="!hasError" class="alert alert-success">
       {{ displayMessage }}
    </div>
    <div *ngIf="hasError" class="alert alert-danger">
    {{ displayMessage }}
    </div>
  </div>
</div>
