export class Media {
    id: number;
    subject: string;
    description: string;
    mediaUrl: string;
    displayFlag: boolean;
    mediaTypeID: number;
    fileName: string;

    constructor(values: object = {}) {
      Object.assign(this, values);
    }
  }
  