
export class ProductUpdate {
  id: number;
  name: string;
  description: string;
  imagePath: string;
  activeFlag: boolean;
  weight: number;
  noShippingFlag: boolean;
  shippingAmount: number;
  priceLabel: string;
  unitPrice: number;
  imageFileData: string;
  imageType: string;
  productCategoryID: number;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
