import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from './../../../environments/environment';
import { StripeRequest } from './../../models/stripe-request.model';
import { Notification } from './../../models/notification.model';
import { EmailAddress } from './../../models/email-address.model';
import { Order } from './../../models/order.model';

import { NotificationService } from './../../services/site/notification.service';
import { OrderService } from './../../services/site/order.service';
import { SessionOrderService } from './../../services/site/session-order.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../services/site/user.service';


@Component({
  selector: 'app-stripe-charge',
  templateUrl: './stripe-charge.component.html',
  styleUrls: ['./stripe-charge.component.scss']
})
export class StripeChargeComponent implements OnInit {

  displayMessage: string;
  hasError = false;
  order = new Order();

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private orderService: OrderService,
    private sessionOrderService: SessionOrderService,
    private toastrService: ToastrService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const chargeInfo = JSON.parse(sessionStorage.getItem('stripe-charge'));
    if (chargeInfo === null) {
      this.toastrService.error('No charge to process');
      this.router.navigate(['/home']);
    } else {
      this.chargeOrder(chargeInfo.orderTotal, chargeInfo.orderId, chargeInfo.email, chargeInfo.token);
    }
  }

  chargeOrder(orderTotal: number, orderId: number, email: string, token: string) {

    const stripeRequest = new StripeRequest({
      orderTotal,
      orderID: orderId,
      emailAddress: email,
      sourceToken: token
    });

    this.userService.saveStripeCharge(stripeRequest)
      .subscribe(data => {
        console.log('chargeOrder data: ' + JSON.stringify(data));
        if (!data.hasErrors) {
          this.hasError = false;
          this.displayMessage = 'Your order has been successfully processed!  Your order is identified by Order #' + orderId + '.';

          this.orderService.getOrder(orderId)
            .subscribe(orderData => {
              const order = orderData;
              console.log('saveStripeCharge-order: ' + JSON.stringify(order));
              this.sendNotification(order);
            });
        } else {
          this.hasError = true;
          this.displayMessage = 'An error occurred during the process of your order.  ' + data.failureCode + ': [' + data.failureMessage + ']. Please retry again or contact TextileFabric Consultants.';
          this.removeStripeStuff();
        }

      }, error => {
        this.hasError = true;
        this.displayMessage = 'An error occurred during the process of your order.  Please retry again or contact TextileFabric Consultants.';
        this.removeStripeStuff();
      });
  }

  sendNotification(order: Order) {
    const notification = this.createOrderNotification(order);
    this.notificationService.SendNotification(notification)
      .subscribe(data => {
        this.removeShoppingCart();
      });
  }

  removeShoppingCart() {
    this.removeStripeStuff();
    sessionStorage.removeItem('shipTo');
    sessionStorage.removeItem('billTo');
    sessionStorage.removeItem('shoppingCart');
  }

  removeStripeStuff() {
    sessionStorage.removeItem('stripe-charge');
    sessionStorage.removeItem('stripe-token');
  }

  createOrderNotification(order: Order) {

    const fromAddress = this.createEmailAddress(environment.fromAddress, 'Textilefabric Orders');
    const toFullName = `${order.billTo.firstName} ${order.billTo.lastName}`;
    const toAddress = this.createEmailAddress(order.billTo.emailAddress, toFullName);

    const body = this.createEmailBody(order);

    return new Notification({
      fromAddress,
      toAddress,
      subject: 'TextileFabric Order Confirmation',
      body
    });
  }

  createEmailAddress(emailAddress: string, fullName: string) {
    return new EmailAddress({
      emailAddress,
      fullName
    });
  }

  createEmailBody(order: Order) {

    let body = 'Thank you for your order at Textile Fabric Consultants, Inc.<br/><br/>';
    body += `<b>Order #</b>${order.orderID}<br/><br/>`;

    const billTo = `<b>Order BillTo</b><br/>${order.billTo.companyName}<br/>${order.billTo.firstName} ${order.billTo.lastName}<br/>${order.billTo.address1} ${order.billTo.aptNumber}<br/>${order.billTo.city}, ${order.billTo.stateCode} ${order.billTo.postalCode} ${order.billTo.countryCode}<br/>`;
    const shipTo = `<b>Order ShipTo</b><br/>${order.shipTo.companyName}<br/>${order.shipTo.firstName} ${order.shipTo.lastName}<br/>${order.shipTo.address1} ${order.shipTo.aptNumber}<br/>${order.shipTo.city}, ${order.shipTo.stateCode} ${order.shipTo.postalCode} ${order.shipTo.countryCode}<br/>`;

    body += `<table><tr><td>${billTo}</td><td>${shipTo}</td></tr></table><br/><br/>`;

    let lineItems = '<table><thead><tr><th>Product</th><th>Quantity</th><th>Price</th><th>Total</th></tr></thead>';
    lineItems += '<tbody>';
    order.lineItems.forEach(lineItem => {
      const item = `<tr><td>${lineItem.productName}</td><td>${lineItem.quantity}</td><td>${lineItem.unitPrice.toFixed(2)}</td><td>${(lineItem.quantity * lineItem.unitPrice).toFixed(2)}</td></tr>`;
      lineItems += item;
    });
    lineItems += '</tbody></table>';

    body += `${lineItems}<br/><table><tr><td><b>SubTotal: </b></td><td>$${order.subTotal.toFixed(2)}</td></tr><tr><td><b>Shipping: </b></td><td>$${order.shippingAmount.toFixed(2)}</td></tr><tr><td><b>Taxes: </b></td><td>$${order.salesTax.toFixed(2)}</td></tr><tr><td><b>Total: </b></td><td>$${order.orderTotal.toFixed(2)}</td></tr></table>`;

    return body;
  }

}
