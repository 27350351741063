<div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div *ngIf="isValidToken">
                <h5 class="card-title">Textile Fabric Reset Password</h5>
                <p class="card-text">Please complete the form below to reset your user password.</p>
                <form [formGroup]="pwdResetForm" (ngSubmit)="submit()">
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Enter password" formControlName="password1" />
                    <div *ngIf="f.password1.touched && f.password1.invalid" class="text-danger">
                    <div *ngIf="f.password1.errors.required">Password is required</div>
                    <div *ngIf="f.password1.errors?.minlength">Password must be at least 8 characters</div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Enter confirm password again" formControlName="password2" />
                    <div *ngIf="f.password2.touched && f.password2.invalid" class="text-danger">
                    <div *ngIf="f.password2.errors.required">Confirm Password is required</div>
                    <div *ngIf="f.password2.errors?.minlength">Confirm Password must be at least 8 characters</div>
                    </div>
                </div>
                <div class="pull-right">
                    <button class="btn btn-primary" type="submit"><i class="fa fa-check"></i>&nbsp;Save Registration</button>
                </div>
                </form>
            </div>
            <div *ngIf="!isValidToken">
              <h5 class="card-title">Invalid Token</h5>
              <p class="card-text">Your token is invalid.  Please click <a routerLink="/login">here</a> there to return to login page.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>