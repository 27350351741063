<div class="container">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Editing a Textile Video</h5>
                    <form [formGroup]="mediaForm">
                        <div class="d-flex flex-column">
                            
                            <div class="p-2">
                                <div class="form-group">
                                    <label>Video Name:</label>
                                    <input type="text" class="form-control" formControlName="subject" placeholder="Enter video name" />
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="form-group">
                                    <label>Description:</label>
                                    <textarea type="text" rows="3" class="form-control" formControlName="description" placeholder="Enter video description"></textarea>
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="form-group">
                                    <label>YouTube Link:</label>
                                    <input type="text" class="form-control" formControlName="mediaUrl" placeholder="Enter YouTube video link" />
                                </div>
                            </div>
                            <div class="p-2">
                                <div class="form-group form-check">
                                    <input type="checkbox" class="form-check-input" formControlName="displayFlag" />
                                    <label class="form-check-label">&nbsp;Is Active</label>
                                </div>
                            </div>
                           
                        </div>
                        <div class="text-right">
                            <button class="btn btn-primary" type="submit" (click)="submit()"><i class="fa fa-check"></i>&nbsp;Save</button>
                          </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
