<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Billing Information</h5>
          <p>
            Please complete the fields below for your billing address.  If you live in an apartment, please enter your apartment
            number.  This will ensure proper billing of your order.
          </p>
          <form [formGroup]="billToForm" (ngSubmit)="submit()">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter School Name" formControlName="companyName" />
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter First Name" formControlName="firstName" />
              </div>
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Last Name" formControlName="lastName" />
                <div *ngIf="f.lastName.touched && f.lastName.invalid" class="alert alert-danger">
                  <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
              </div>
            </div>
            <div class="form-group">

            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Address Line #1" formControlName="address1" />
                <div *ngIf="f.address1.touched && f.address1.invalid" class="alert alert-danger">
                  <div *ngIf="f.address1.errors.required">Address Line #1 is required</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Apt #" formControlName="aptNumber" />
              </div>
            </div>
            <div class="form-group">

            </div>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter Address Line #2" formControlName="address2" />
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-3">
                <input type="text" class="form-control" placeholder="Enter City" formControlName="city" />
                <div *ngIf="f.city.touched && f.city.invalid" class="alert alert-danger">
                  <div *ngIf="f.city.errors.required">City is required</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <select class="form-control" formControlName="stateCode">
                  <option value="null" selected disabled>--Select State--</option>
                  <option *ngFor="let state of (states$ | async)" [ngValue]="state.stateCode">{{ state.name }}</option>
                </select>
                <div *ngIf="f.stateCode.touched && f.stateCode.invalid" class="alert alert-danger">
                  <div *ngIf="f.stateCode.errors.required">State is required.</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <input type="text" class="form-control" placeholder="Enter Postal Code" formControlName="postalCode" />
                <div *ngIf="f.postalCode.touched && f.postalCode.invalid" class="alert alert-danger">
                  <div *ngIf="f.postalCode.errors.required">Postal Code is required</div>
                  <div *ngIf="f.postalCode.errors.maxlength">Postal Code cannot be longer than 5 characters.</div>
                  <div *ngIf="f.postalCode.errors.minlength">Postal Code must be 5 characters.</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <select class="form-control" formControlName="countryCode">
                  <option value="null" selected disabled>--Select Country--</option>
                  <option *ngFor="let country of (countries$ | async)" [ngValue]="country.countryCode">{{ country.name }}</option>
                </select>
                <div *ngIf="f.countryCode.touched && f.countryCode.invalid" class="alert alert-danger">
                  <div *ngIf="f.countryCode.errors.required">Country is required</div>
                </div>
              </div>
            </div>

              <div class="row">
                &nbsp;
              </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Phone Number" formControlName="phoneNumber" />
              </div>
              <div class="col-sm-12 col-md-6">
                <input type="text" class="form-control" placeholder="Enter Email Address" formControlName="emailAddress" />
                <div *ngIf="f.emailAddress.touched && f.emailAddress.invalid" class="alert alert-danger">
                  <div *ngIf="f.emailAddress.errors.email">Email Address must be valid</div>
                </div>
              </div>
            </div>

            <div class="toolbar">
              <div class="row">
                <div class="col-sm-12 text-right">
                  <div class="form-group">
                    <button class="btn btn-primary" type="submit" [disabled]="billToForm.invalid"><i class="fa fa-check"></i>&nbsp;Next >></button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
