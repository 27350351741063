<div class="container">
  <div class="row">
    <div class="col-sm-12 title-bar">
      <!-- <h2>Textile Fabric Product Categories</h2> -->
      <div *ngIf="parent.name.length > 0">
        <h3>({{ parent.name }})</h3>
      </div>
      <!-- <p>Click on the category title below to view the products</p> -->
    </div>
  </div>

  <div class="row">
    <div class="d-flex flex-wrap align-content-start justify-content-center">
      <div *ngFor="let category of categories">
        <div class="col-12 card-data">
          <div class="card" style="width: 18rem; height:380px">
            <div class="card-body">

              <div *ngIf="category.children.length === 0">
                <a [routerLink]="['/products', category.id]">
                  <div *ngIf="showImages">
                    <img class="card-img-top" src="{{ imageSource }}/{{ category.imagePath }}" alt="Card image cap">
                  </div>
                  <h5 class="card-title">
                    {{ category.name }}
                  </h5>
                </a>
              </div>
              <div *ngIf="category.children.length > 0">
                <a [routerLink]="['/categories', category.id]">
                  <img class="card-img-top" src="{{ imageSource }}/{{ category.imagePath }}" alt="Card image cap">
                  <h5 class="card-title">
                    {{ category.name }}
                  </h5>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
