import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from './../../../../environments/environment';

import { Media } from '../../../models/media.model';

import { AdminService } from './../../../services/site/admin.service';
import { LoadingService } from './../../../shared/loading/loading.service';
import { TokenService } from './../../../services/auth/token.service';

@Component({
  selector: 'app-admin-media',
  templateUrl: './admin-media.component.html',
  styleUrls: ['./admin-media.component.scss']
})
export class AdminMediaComponent implements OnInit {

  medias: Media[] = [];
  
  constructor(
    private readonly adminService: AdminService,
    private readonly loadingService: LoadingService,
    private readonly tokenService: TokenService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.tokenService.isAdmin()) {
      this.router.navigate(['/home']);
    }
    this.listMedias();
  }

  
  listMedias() {
    this.adminService.listMedias()
      .subscribe(data => {
        this.medias = data;
        console.log('media: ' + JSON.stringify(this.medias));
      });
  }

}
