import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CartItem } from './../../models/cart-item.model';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  cart: CartItem[];
  private currentCartCountSubject: BehaviorSubject<number>;
  public cartCount: Observable<number>;

  constructor() {
    this.currentCartCountSubject = new BehaviorSubject<number>(0);
    this.cartCount = this.currentCartCountSubject.asObservable();
   }

   public get cartCountValue(): number {
     return this.currentCartCountSubject.value;
   }

  addItem(productId: number, name: string, quantity: number, unitPrice: number, weight: number, noShippingFlag: boolean) {
    const cart = JSON.parse(sessionStorage.getItem('shoppingCart'));
    if (cart === null || cart === undefined) {
      this.cart = [];
    }

    let found = false;
    if (this.cart === undefined) {
      this.cart = [];
    }
    if (this.cart.length > 0) {
      this.cart.forEach(item => {
        if (item.productId === productId) {
          found = true;
          item.quantity = quantity;
          item.itemTotal = item.quantity * item.unitPrice;
        }
      });
    }

    if (!found) {
      const item = new CartItem({ productId, name, quantity, unitPrice, weight, noShippingFlag });
      item.itemTotal = quantity * unitPrice;
      this.cart.push(item);
    }

    console.log('weight: ' + weight);
    console.log('cart:' + JSON.stringify(this.cart));

    this.currentCartCountSubject.next(this.cart.length);
    sessionStorage.setItem('shoppingCart', JSON.stringify(this.cart));
  }

  deleteItem(item: CartItem) {
    let found = false;
    const idx = this.cart.indexOf(item);
    if (idx > -1) {
      found = true;
      this.cart.splice(idx, 1);
    }
    if (found) {
      this.currentCartCountSubject.next(this.cart.length);
      sessionStorage.setItem('shoppingCart', JSON.stringify(this.cart));
    }
  }

  getItems() {
    this.cart = JSON.parse(sessionStorage.getItem('shoppingCart'));
    if (this.cart !== undefined && this.cart !== null)
    {
      this.cart.forEach(i => {
        i.quantity = Number(i.quantity);
      });
    }

    return this.cart;
  }
}
