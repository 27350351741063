<div class="container">
  <div class="row">
    <div class="d-flex flex-wrap align-content-start justify-content-center">
      <div class="col-12">
        <h3 class="page-title">About Textile Fabric Consultants, Inc</h3>
      </div>
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title alert alert-info">Our Team</h5>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-4 card-group">
                <div class="card" style="width: 18rem;">
                  <img class="card-img-top" src="/assets/images/Amy.png" alt="Amy Willbanks">
                  <div class="card-body">
                    <h5 class="card-title">Amy Willbanks</h5>
                    <p class="card-text">Owner/President</p>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 card-group">
                <div class="card" style="width: 18rem;">
                  <img class="card-img-top" src="/assets/images/Susan.png" alt="Amy Willbanks">
                  <div class="card-body">
                    <h5 class="card-title">Susan Elliott</h5>
                    <p class="card-text">Accounts Manager</p>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 card-group">
                <div class="card" style="width: 18rem;">
                  <img class="card-img-top" src="/assets/images/Kamryn.png" alt="Amy Willbanks">
                  <div class="card-body">
                    <h5 class="card-title">Kamryn Elliott</h5>
                    <p class="card-text">Photographer and Videographer</p>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 card-group">
                <div class="card" style="width: 18rem;">
                  <img class="card-img-top" src="/assets/images/Corri.png" alt="Amy Willbanks">
                  <div class="card-body">
                    <h5 class="card-title">Corri Logan</h5>
                    <p class="card-text">Fulfillment Operations Associate</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title alert alert-success">Our History</h5>
            <p class="card-text">Textile Fabric Consultants, Inc. was established in 1981 when a new textiles instructor, Nancy Oxford, developed a swatch kit 
              for her textile students.   Not  finding a swatch kit on the market to fit her needs, she purchased fabrics and cut them into swatches by hand.  
              Nancy’s kit soon caught the attention of other professors and they began requesting the kit for their courses.  With the help of her roommate, 
              Textile Fabrics began to grow.  Eventually, the swatch kit production moved from her kitchen table to her 700 square foot garage.  
              <br/><br/>
              In 1991, Nancy, now an instructor at Middle Tennessee State University, could no longer teach fulltime and run the business.  She hired Amy Willbanks, 
              a former student, to operate the business on a daily basis.  Under Amy’s leadership and with Nancy’s innovations, Textile Fabric Consultants continued 
              to grow from the modest garage to an 8000 square foot facility in Smyrna, Tennessee.  In 2019, Amy became the owner and president of TFC.
              <br/><br/>
              Now over 43 years later, TFC is still producing the Basic Textiles Swatch Kit that started it all.  We have expanded our product line to include 
              multiple swatch kit options, custom bundles and lab kits for both fashion and interior design majors.  These products are used by high schools, 
              colleges, and universities around the globe.
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title alert alert-info">Our Service</h5>
            <p class="card-text">We are committed to meeting the needs of our customers, employees, community and the environment.</p>
            <p class="card-text"><b>How we serve our customers?</b> We take pride in meeting the needs of our customers, accurately and efficiently by providing excellent customer service. We have no high tech automated telephone menus to go through; you get a warm friendly voice when you call during office hours.</p>
            <p class="card-text"><b>How we serve our employees?</b> TFC cares for its employees by providing a friendly and safe working environment. We instill in each employee the desire and drive to truly care about their work by encouraging them and allowing them the opportunity to grow with our company. TFC values its employees and justly awards them for their meticulous attention to details that ensure our customers are satisfied. </p>
            <p class="card-text"><b>How we serve our community?</b> TFC believes strongly in giving back to the community. So many companies help us by donating fabrics to keep our costs down for the swatch kits. We, in turn, donate our extra fabric scraps to local churches, Girl Scout troops and animal adoption shelters. We consider it an honor to be able to provide these fabrics to these organizations at no cost.</p>
            <p class="card-text"><b>How we serve our environment?</b> TFC is constantly researching areas of sustainability. Recycling is just one of the ways we contribute to the preservation of our environment. We reuse packing materials, recycle cardboard, plastic and aluminum. Our fabric scraps are donated to community groups. TFC also keeps our customers informed about sustainable products by including sustainable textiles in the swatch kits and information on sustainability in our monthly newsletters. </p>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title alert alert-warning">Our Mission</h5>
            <p class="card-text">Our mission is to provide quality textile education products by upholding our core values as outlined below: </p>
            <p class="card-text">We strive to exceed our customers' expectations in quality, delivery, and cost through continuous improvement and customer interaction.</p>
            <p class="card-text">We set the industry standard in service to customers.</p>
            <p class="card-text">We maintain a superior level of integrity in interactions with our customers, business partners and associates.</p>
            <p class="card-text">We appreciate our achieved success and we conduct our business as model corporate citizens. </p>
            <p class="card-text">We do our best to provide the timely delivery of products whose performance and appearance are in accordance with our tradition of high manufacturing standards. </p>
            <p class="card-text">We sustain our mission by constantly seeking renewal via continuous education and learning, and the application of new technologies and best business practices.</p>
            <p class="card-text">We provide a pleasant, nurturing and growth oriented environment which encourages our employees to be highly productive and to grow personally and professionally.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
