import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SlugifyPipe } from './../../shared/pipes/slugify.pipe';

import { CategoryProduct } from './../../models/category-product.model';
import { Product } from './../../models/product.model';

import { CategoryService } from './../../services/site/category.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [SlugifyPipe]
})
export class ProductsComponent implements OnInit {

  category: CategoryProduct;

  constructor(
    private slugify: SlugifyPipe,
    private readonly categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.category = new CategoryProduct({ id: 0, name: '', products: [] });
    this.route.params.subscribe(params => {
      console.log('catid: ' + params.id);
      this.getProducts(params.id);
    });

  }

  getProducts(categoryId: number) {
    this.categoryService.getProducts(categoryId)
      .subscribe(data => {
        this.category.products = [];
        data.products.forEach(d => {
          if (d.imagePath === undefined || d.imagePath === null) {
            d.imagePath = 'noimage.jpg';
          }
          const product = new Product({ id: d.id, name: d.name, description: d.description,
                            imagePath: d.imagePath, activeFlag: d.activeFlag,
                            weight: d.weight, alternateName: d.alternateName,
                            noShippingFlag: d.noShippingFlag, shippingAmount: d.shippingAmount,
                            createdAt: d.createdAt, updatedAt: d.updatedAt, priceLabel: d.priceLabel,
                            unitPrice: d.unitPrice, featuredFlag: d.featuredFlag,
                            slug: this.slugify.transform(d.name) });
          this.category.products.push(product);
        });
        this.category = data;
      });
  }

}
