import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Media } from 'src/app/models/media.model';
import { MediaType } from 'src/app/models/media-type.model';

import { AdminService } from './../../../../services/site/admin.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { TokenService } from './../../../../services/auth/token.service';

@Component({
  selector: 'app-admin-media-detail',
  templateUrl: './admin-media-detail.component.html',
  styleUrls: ['./admin-media-detail.component.scss']
})
export class AdminMediaDetailComponent implements OnInit{

  mediaForm: FormGroup;
  media: Media = new Media();
  mediaTypes: MediaType[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly tokenService: TokenService
  ) {}

  ngOnInit(): void {
    if (!this.tokenService.isAdmin()) {
      this.router.navigate(['/home']);
    }
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);

    this.mediaForm = this.createMediaForm();
    this.media = this.createMediaEntity();

    this.route.params.subscribe(params => {
      console.log('id: ' + params.id);
      if (params.id > 0) {
        this.getMedia(params.id);
      this.mediaForm = this.setMediaForm(this.media);
      }
      
    });
  }

  get f() { return this.mediaForm.controls; }

  createMediaEntity() {
    return new Media({
      id: 0,
      subject: '',
      description: '',
      mediaUrl: '',
      fileName: '',
      mediaTypeID: 1,
      displayFlag: true
    });
  }

  createMediaForm() {
    return this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
      mediaUrl: ['', Validators.required],
      displayFlag: [true]
    });
  }

  setMediaEntityFromForm() {
    return new Media({
      id: this.media.id,
      subject: this.f.subject.value,
      description: this.f.description.value,
      mediaUrl: this.f.mediaUrl.value,
      displayFlag: this.f.displayFlag.value,
      filename: this.media.fileName,
      mediaTypeID: this.media.mediaTypeID
    })
  }

  getMedia(id) {
    this.adminService.getMedia(id).subscribe(media => {
      this.media = media;
      console.log('getMedia: ' + JSON.stringify(this.media));
      this.mediaForm = this.setMediaForm(this.media);
    })
  }

  getMediaTypes() {
    this.adminService.getMediaTypes().subscribe(mediaTypes => {
      this.mediaTypes = mediaTypes;
    });
  }

  setMediaForm(media: Media) {
    return this.fb.group({
      subject: [media.subject, Validators.required],
      description: [media.description, Validators.required],
      mediaUrl: [media.mediaUrl, Validators.required],
      displayFlag: [media.displayFlag]
    });
  }

  submit() {
    if (this.mediaForm.invalid) {
      return;
    }

    const mediaUpdate = this.setMediaEntityFromForm();
    console.log('mediaUpdate: ' + JSON.stringify(mediaUpdate));

    if (mediaUpdate.id == 0) {
      this.adminService.createMedia(mediaUpdate).subscribe(() => {
        this.router.navigate(['/admin/media']);
      });
    } else {
      this.adminService.updateMedia(mediaUpdate).subscribe(() => {
        this.router.navigate(['/admin/media']);
      });
    }

  }

}
