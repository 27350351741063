import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { AdminCategory } from './../../models/admin-category.model';
import { AdminCategoryUpdate } from './../../models/admin-category-update.model';
import { CategoryUpdate } from './../../models/category-update.model';
import { Category } from './../../models/category.model';
import { Product } from './../../models/product.model';
import { CategoryProduct } from './../../models/category-product.model';

import { ErrorService } from './../utility/error.service';
import { RestService } from './../base/rest.service';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends RestService {

  endpointUrl: string;
  categories: Category[];

  constructor(
    private http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    super(http, errorService);
    this.endpointUrl = '/api/Category';
  }

  public getCategories(): Observable<Category[]> {
    const url = `${this.endpointUrl}`;
    return this.get<Category[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getAdminCategories(): Observable<AdminCategory[]> {
    const url = `${this.endpointUrl}`;
    return this.get<AdminCategory[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getCategory(categoryId: number): Observable<AdminCategory> {
    const url = `${this.endpointUrl}/${categoryId}`;

    return this.get<AdminCategory>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getSubcategories(parentId: number) {
    const url = `${this.endpointUrl}/${parentId}/subcategories`;

    return this.get<AdminCategory>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getProducts(categoryId: number): Observable<CategoryProduct> {
    const url = `${this.endpointUrl}/${categoryId}/products`;
    return this.get<CategoryProduct>(url)
      .pipe(
          catchError(this.errorService.handleError)
      );
  }

  public getProduct(productId: number): Observable<Product> {
    const url = `${this.endpointUrl}/products/${productId}`;

    return this.get<Product>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public createCategory(category: AdminCategory): Observable<AdminCategory> {
    const url = `${this.endpointUrl}`;

    return this.post<AdminCategory>(url, category)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public updateCategory(category: CategoryUpdate): Observable<CategoryUpdate> {
    const url = `${this.endpointUrl}/${category.id}`;

    return this.put<CategoryUpdate>(url, category)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  
}
