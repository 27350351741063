
export class StripeRequest {
  orderTotal: number;
  orderID: number;
  emailAddress: string;
  sourceToken: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
