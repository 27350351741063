import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { UserRegistration } from './../../models/user-registration.model';

import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../services/site/user.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationComponent implements OnInit {

  userRegForm: FormGroup;
  submitted = false;
  registration: UserRegistration;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private readonly toastrService: ToastrService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.createRegistrationForm();
  }

  get f() { return this.userRegForm.controls; }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('passwordConfirm').value
       ? null : {'mismatch': true};
  }

  createRegistrationForm() {
    this.userRegForm = this.fb.group({
      firstName:  [''],
      lastName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email ]],
      password1: ['', [Validators.required, Validators.minLength(8)]],
      password2: ['', [Validators.required, Validators.minLength(8)]]
    }, this.passwordMatchValidator);
  }

  getFormValues() {
    return new UserRegistration({
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      emailAddress: this.f.emailAddress.value,
      password1: this.f.password1.value,
      password2: this.f.password2.value
    });
  }

  submit() {
    this.submitted = true;

    if (!this.userRegForm.valid) {
      this.toastrService.error('Registration form is invalid');
      return;
    }

    this.registration = this.getFormValues();

    this.userService.saveRegistration(this.registration)
      .subscribe(data => {
        console.log('reg-data: ' + JSON.stringify(data));
        this.toastrService.success('Successfully saved registration');
        this.router.navigate(['/login']);
      }, error => {
        console.log('reg error: ' + JSON.stringify(error));
        this.toastrService.error(error);
      });



  }

}
