<div class="registration-container">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Textile Fabric Registration</h5>
          <p class="card-text">Please complete the form below to register for the Textile Fabric website.</p>
          <form [formGroup]="userRegForm" (ngSubmit)="submit()">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter first name" formControlName="firstName" />
            </div>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter last name" formControlName="lastName" />
              <div *ngIf="f.lastName.touched && f.lastName.invalid" class="text-danger">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Enter email address" formControlName="emailAddress" />
              <div *ngIf="f.emailAddress.touched && f.emailAddress.invalid" class="text-danger">
                <div *ngIf="f.emailAddress.errors.required">Email Address is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="password" class="form-control" placeholder="Enter password" formControlName="password1" />
              <div *ngIf="f.password1.touched && f.password1.invalid" class="text-danger">
                <div *ngIf="f.password1.errors.required">Password is required</div>
                <div *ngIf="f.password1.errors?.minlength">Password must be at least 8 characters</div>
              </div>
            </div>
            <div class="form-group">
              <input type="password" class="form-control" placeholder="Enter confirm password again" formControlName="password2" />
              <div *ngIf="f.password2.touched && f.password2.invalid" class="text-danger">
                <div *ngIf="f.password2.errors.required">Confirm Password is required</div>
                <div *ngIf="f.password2.errors?.minlength">Confirm Password must be at least 8 characters</div>
              </div>
            </div>
            <div class="pull-right">
              <button class="btn btn-primary" type="submit"><i class="fa fa-check"></i>&nbsp;Save Registration</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
