<div class="container">
  <div class="row">
    <div class="col-12">

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Textile Videos</h5>
        </div>
        <div class="media-list">
           <table class="table">
            <tbody>
              <tr *ngFor="let media of medias">
                <td>
                  <i class="bi bi-youtube"></i>&nbsp;<a href="{{ media.mediaUrl }}" target="_blank">{{ media.subject }}</a>
                </td>
              </tr>
            </tbody>
          </table>
          
        </div>
      </div> 
    </div>
  </div>
</div>
  