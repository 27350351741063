import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable()
export class AdminUsersResolver  {

  baseUrl: string;

  constructor(private http: HttpClient) { }

  resolve(): Observable<any> {
    const baseUrl = environment.apiUrl;
    const usersUrl = `${baseUrl}/api/Admin/users`;

    return this.http.get(usersUrl).pipe(
      map( (dataFromApi) => dataFromApi ),
      catchError( (err) => throwError(err.json().error) )
    );
  }
}
