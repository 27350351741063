<div class="container" style="padding-left: 0px !important;">
  <div class="row">
    <div class="col-12">
      <!-- <div class="home-page-content">

      </div> -->
      <div class="text-center home-image">
        <img src="/assets/images/home4_1200.png" alt="Fabrics Image" />
        &nbsp;
      </div>
    </div>
  </div>

  <div class="row welcome-video-container">
    <div class="col-12">
     <div class="text-center">
      <!-- <iframe src="https://www.youtube.com/embed/8xkm9NXCeYk?si=b-eyQBwka5C07LlY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
      <iframe width="560" height="315" src="https://www.youtube.com/embed/ZlChabQWxG0?si=4XJVDO85EylinxuY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

<div class="container featured-section">
  <div class="row">
    <div class="col-12">
      <div class="featured-section-title">
        Featured Products
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="featured-product">
        <img src="/assets/images/btsk.png" alt="btsk" class="featured" /><br />
        <p class="featured-product-title">Basic Textiles Swatch Kit 2024 Edition</p>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="featured-product2">
        <img src="/assets/images/textilepedia.png" alt="textilepedia" class="featured" /><br/>
        <p class="featured-product-title">Textilepedia</p>
      </div>
      
    </div>
    <div class="col-12 single-featured">
      <div class="featured-product3">
        <img src="/assets/images/yarn_count.png" alt="yarn" class="featured" />
        <p class="featured-product-title">Learn How To Perform A Yarn/Fiber Count</p>
        <p>For more content like this, subscribe to our Youtube channel!</p>
      </div>
    </div>

  </div>
  
</div>