<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="card mt-3 tab-card">
        <div class="card-header tab-card-header">
          <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="One" aria-selected="true">Demographics</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="Two" aria-selected="false">Orders</a>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="one-tab">

              <form [formGroup]="accountForm" (ngSubmit)="submit()">
                <div class="d-flex flex-column">
                  <fieldset class="demographics-general">
                    <legend>General</legend>
                    <div class="d-flex">
                      <div class="p-2 flex-fill">
                        <div class="form-group">
                          <input type="text" class="form-control" formControlName="firstName" placeholder="Enter first name" />
                        </div>
                        <div class="form-group">
                          <input type="text" class="form-control" formControlName="lastName" placeholder="Enter last name" />
                        </div>
                      </div>
                      <div class="p-2 flex-fill">
                        <div class="form-group">
                          <input type="text" class="form-control" formControlName="companyName" placeholder="Enter school name" />
                        </div>
                        <div class="form-group form-check">
                          <input type="checkbox" class="form-check-input" formControlName="activeFlag" />
                          <label class="form-check-label">&nbsp;Is Active User</label>
                        </div>

                      </div>
                    </div>

                  </fieldset>
                </div>

                <div class="d-flex">
                  <div class="p-2 flex-fill">
                    <fieldset class="billing-content">
                      <legend>Billing Information</legend>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="billingAddress1" placeholder="Enter billing address #1" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="billingAddress2" placeholder="Enter billing address #2" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="billingCity" placeholder="Enter billing city" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="billingState" placeholder="Enter billing state" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="billingPostalCode" placeholder="Enter billing postal code" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="billingCountryCode" placeholder="Enter billing country code" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="billingPhoneNumber" placeholder="Enter billing phone number" />
                      </div>
                    </fieldset>
                  </div>
                  <div class="p-2 flex-fill">
                    <fieldset class="billing-content">
                      <legend>Mailing Information</legend>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="mailingAddress1" placeholder="Enter mailing address #1" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="mailingAddress2" placeholder="Enter mailing address #2" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="mailingCity" placeholder="Enter mailing city" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="mailingState" placeholder="Enter mailing state" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="mailingPostalCode" placeholder="Enter mailing postal code" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="mailingCountryCode" placeholder="Enter mailing country code" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" formControlName="mailingPhoneNumber" placeholder="Enter mailing phone number" />
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="p-2 flex-fill text-right">
                    <button type="submit" class="btn btn-primary"><i class="fa fa-check"></i>&nbsp;Save Record</button>
                  </div>
                </div>
              </form>

          </div>
          <div class="tab-pane fade p-3" id="two" role="tabpanel" aria-labelledby="two-tab">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Order #</th>
                  <th class="text-center">Order Date</th>
                  <th class="text-center">Order Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let o of orders">
                  <td><a [routerLink]="['/admin/details', o.id]">{{ o.id }}</a></td>
                  <td class="text-center">{{ o.createdAt | date : 'short' }}</td>
                  <td class="text-center">{{ o.orderTotal | currency }}</td>
                </tr>
                <tr *ngIf="orders.length === 0">
                  <td colspan="3">No user orders available</td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>