import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UserService } from './../../services/site/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private readonly toastrService: ToastrService,
    private readonly userService: UserService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
    this.resetForm = this.createResetForm();
  }

  get f() { return this.resetForm.controls; }

  createResetForm() {
    return this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  submit() {
    this.submitted = true;

    if (this.resetForm.invalid) {
      return;
    }

    this.spinner.show();
    console.log('email: ' + this.f.email.value);
    this.userService.resetPassword(this.f.email.value)
      .subscribe(data => {
        if (data.isSuccessful) {
          this.toastrService.success('Reset password email was successfully sent');
          this.spinner.hide();
        } else {
          this.toastrService.error(data.errors[0]);
          this.spinner.hide();
        }

      });
  }

}
