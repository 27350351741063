<div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12">

        <div class="card">
          <div class="card-body">
            <div class="col-sm-12">
              <h4 class="card-title">Frequently Asked Questions</h4>
              <p class="card-text">
                <b>How do I order extra mounting sheets?</b><br />
                When they click on this we would have the links to the different mounting sheets so they could add them to their carts.
              </p>
              <p class="card-text">
                <b>What to do if I am missing swatches?</b><br />
                We will be glad to help you with any problem you may have with your swatch kit.  Swatches are rarely truly missing.  They are typically stuck behind the previous swatch.
              </p>
              <p class="card-text">
                <b>What to do if I need to return my product?</b><br />
                Click <a routerLink="/returns">here</a> to view our Return Policy.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Mounted Swatches and YouTube Channel</h5>
            <div class="card-text">
              Please refer to the Mounted Swatches section on our homepage and the swatch kit assembly videos on our Youtube Channel (<a href="http://www.youtube.com/@textilefabricconsultants5281">TextileFabric Youtube Channel</a>) for additional help in locating the swatch.
            </div>
          </div>
        </div>
      </div>
    </div>
        
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Help Locating Swatches</h5>
            <div class="card-text">
              Please text Amy at 615-294-6179 to schedule a FaceTime.  She will be glad to help you locate the swatches and/or send you the ones you need.
            </div>
          </div>
        </div>
      </div>
    </div>
        
    <!-- <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Swatch Mounting Video Links</h5>
            <div class="card-text">
              <br/>
              <table class="table">
                <tbody>
                <tr>
                  <td class="text-center">
                    <a href="https://youtu.be/NP2aanCgSaY?si=UHogOBXiz5m9NxCc" target="_blank">Basic 2022 edition Mounting Video</a><br/><br/>
                    <a href="https://youtu.be/2kjLSKcUsIE?si=0ETJR8pVNSiX7znw" target="_blank">Basic 2024 edition Mounting Video</a><br/><br/>
                    <a href="https://www.youtube.com/watch?v=XbYsBmimwMY" target="_blank">Materials Kit For Interior Design 2023 Edition Mounting Video</a><br/><br/>
                  </td>
                  <td class="text-center">
                    <a href="https://youtu.be/5Ma4an4HBDg?si=iDr2DqRhCLJMVYjD" target="_blank">Interior Design Swatch Kit 2022 Edition</a><br/><br/>
                    <a href="https://www.youtube.com/watch?v=vqZyOolBq3M" target="_blank">Interior Design Swatch Kit 2024 Edition</a><br/><br/>
                    <a href="https://www.youtube.com/watch?v=UY8FHnIlWYs" target="_blank">Consumer Textiles Swatch Kit 2021 Edition Mounting Video</a><br/><br/>
                  </td>
                  <td class="text-center">
                    <a href="https://youtu.be/H2F-IUrE8VU?si=HgO59NJGNDK-CNiS" target="_blank">Yarn Count Tutorial</a><br/><br/>
                    <a href="https://youtu.be/R_aZLp_vilA?si=1atTy9IdvGDfPho3" target="_blank">Using The Fiber Samples Packet</a><br/><br/>
                    <a href="https://youtu.be/UDWhTUb5eRk?si=AowX20-WzsKzL1hJ" target="_blank">Identifying Spun and Filament Yarns</a><br/><br/>
                  </td>
                </tr>
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> -->
     
  </div>
  