import { Component, OnInit, AfterViewInit } from '@angular/core';

import { SlugifyPipe } from './../../shared/pipes/slugify.pipe';
import { environment } from './../../../environments/environment';

import { Category } from './../../models/category.model';
import { Subcategory } from './../../models/subcategory.model';
import { CategoryService } from './../../services/site/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  providers: [SlugifyPipe]
})
export class CategoriesComponent implements OnInit, AfterViewInit {

  categories: Category[];
  parent: Category;
  showImages = true;

  //imageSource = `/assets/images`;
  imageSource = `${environment.awsUrl}/categories`;

  constructor(
    private slugify: SlugifyPipe,
    private readonly categoryService: CategoryService
  ) { }

  ngOnInit(): void {
    this.parent = { id: 0, name: '', imagePath: '', slug: '', children: [], parent: null };
  }

  ngAfterViewInit(): void {
    this.getCategories();
  }

  getCategories() {
    console.log('getCategories');
    this.categoryService.getCategories()
      .subscribe(data => {
        this.categories = [];
        this.parent = { id: 0, name: '', imagePath: '', slug: '', children: [], parent: null };

        if (this.parent.name.toLowerCase() === 'special orders') {
          data = data.sort((a, b) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            else { return 0; }
          });
        }

        data.forEach(d => {
          if (d.imagePath === undefined || d.imagePath == null || d.imagePath.length === 0) {
            d.imagePath = 'noimage.jpg';
          }
          const item = {
            id: d.id,
            name: d.name,
            imagePath: d.imagePath,
            slug: this.slugify.transform(d.name),
            children: d.children,
            parent: null
          };
          this.categories.push(item);
        });

      });
  }

  showSubcategories(subCategories: Category[], parent: Category) {
    this.categories = [];
    this.showImages = false;
    this.parent = parent;
    subCategories.forEach(d => {
      if (d.imagePath === undefined || d.imagePath == null || d.imagePath.length === 0) {
        d.imagePath = 'noimage.jpg';
      }
      const item: Category = {
        id: d.id,
        name: d.name,
        imagePath: d.imagePath,
        slug: this.slugify.transform(d.name),
        children: d.children,
        parent
      };
      this.categories.push(item);
    });

  }

}
