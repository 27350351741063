export class CategoryUpdate
{
  id: number;
  name: string;
  imagePath: string;
  displayOrder: number;
  parentID: number;
  activeFlag: boolean;
    
  constructor(values: object = {}) {
    Object.assign(this, values);
  }

}
