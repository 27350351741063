import { Component, OnInit } from '@angular/core';
import { CartItem } from './../../models/cart-item.model';

import { ShoppingCartService } from './../../services/site/shopping-cart.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  cart: CartItem[];
  hasItems = false;

  constructor(
    private shoppingCartService: ShoppingCartService
  ) { }

  ngOnInit(): void {
    this.getShoppingCart();
  }

  getShoppingCart() {
    this.cart = this.shoppingCartService.getItems();
    if (this.cart !== undefined && this.cart !== null) {
      this.hasItems = true;
      console.log('cart-data: ' + JSON.stringify(this.cart));
    } else {
      this.hasItems = false;
    }

  }

  updateItem(item) {
    console.log('upd-item: ' + JSON.stringify(item));
    this.shoppingCartService.addItem(item.productId, item.name, item.quantity, item.unitPrice, item.weight, item.noShippingFlag);
    this.getShoppingCart();
  }

  deleteItem(item) {
    this.shoppingCartService.deleteItem(item);
    this.getShoppingCart();
  }

}
