<div class="container">
  <div class="row">
    <div class="col-sm-12 title-bar">
      <h3>{{category.name}}&nbsp;<small>({{category.products.length}}&nbsp;items)</small></h3>
    </div>
    <div class="col-sm-12">
      <div *ngFor="let p of category.products">
        <div class="card-content">
          <app-product [product]="p"></app-product>
        </div>
      </div>
    </div>
  </div>
</div>
