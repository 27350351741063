import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import * as uuid from 'uuid';

import { environment } from './../../../environments/environment';

import { ConfirmOrder } from './../../models/confirm-order.model';
import { ConfirmBillTo } from './../../models/confirm-bill-to.model';
import { ConfirmShipTo } from './../../models/confirm-ship-to.model';
import { ConfirmOrderDetails } from './../../models/confirm-order-details.model';
import { OrderShipping } from './../../models/order-shipping.model';
import { CartItem } from './../../models/cart-item.model';

import { ShoppingCartService } from './../../services/site/shopping-cart.service';
import { UserService } from './../../services/site/user.service';
@Injectable({
  providedIn: 'root'
})
export class SessionOrderService {

  sessionOrder: ConfirmOrder;
  originatingPostalCode = environment.tfcPostalCode;
  salesTaxRate = Number(environment.salesTaxRate);
  processing = false;

  constructor(
    private readonly shoppingCartService: ShoppingCartService,
    private readonly userService: UserService
  ) { }

  public getSessionOrder(orderId: number): ConfirmOrder {
    console.log('getSessionOrder');

    this.sessionOrder = this.initializeConfirmOrder(orderId);
    this.sessionOrder.subTotal = this.calculateSubTotal(this.sessionOrder.lineItems);
    this.sessionOrder.salesTax = this.calculateSalesTaxes(this.sessionOrder.billTo);
    // this.calculateShippingTotal(this.sessionOrder.lineItems, environment.tfcPostalCode, this.sessionOrder.shipTo.postalCode);

    // console.log('sessionOrder.shippingTotal: ' + this.sessionOrder.shippingTotal);

    // this.sessionOrder.orderTotal = this.sessionOrder.subTotal + this.sessionOrder.shippingTotal + this.sessionOrder.salesTax;

    return this.sessionOrder;

 }

  private initializeConfirmOrder(orderId: number) {
    console.log('initializeConfirmOrder');
    return new ConfirmOrder({
      billTo: this.getSessionShipTo(),
      shipTo: this.getSessionShipTo(),
      orderDetails: this.getSessionOrderDetails(orderId),
      lineItems: this.getSessionLineItems(),
      subTotal: 0,
      salesTax: 0,
      shippingTotal: 0,
      orderTotal: 0,
      userId: 0,
      accountId: 0,
      identifier: uuid.v4()
    });
  }

  private getSessionBillTo() {
    console.log('getSessionBillTo');
    let data = JSON.parse(sessionStorage.getItem('billTo'));
    if (data === null || data === undefined) {
      data = new ConfirmBillTo();
    }
    return new ConfirmBillTo({
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      address1: data.address1,
      aptNumber: data.aptNumber,
      address2: data.address2,
      fullAddress: this.getSessionAddressLine(data.address1, data.aptNumber, data.address2),
      city: data.city,
      stateCode: data.stateCode,
      postalCode: data.postalCode,
      countryCode: data.countryCode,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber
    });
  }

  private getSessionAddressLine(address1: string, aptNumber: string, address2: string) {
    let address1Str = address1;
    if (aptNumber.trim().length > 0) {
      address1Str = address1Str + ' #' + aptNumber;
    }
    if (address2.trim().length > 0) {
      address1Str = address1Str + ' ' + address2;
    }
    return address1Str;
  }

  private getSessionShipTo() {
    console.log('getSessionShipTo');
    let data = JSON.parse(sessionStorage.getItem('shipTo'));
    if (data === null || data === undefined) {
      data = new ConfirmShipTo();
    }
    return new ConfirmShipTo({
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      address1: data.address1,
      aptNumber: data.aptNumber,
      address2: data.address2,
      fullAddress: this.getSessionAddressLine(data.address1, data.aptNumber, data.address2),
      city: data.city,
      stateCode: data.stateCode,
      postalCode: data.postalCode,
      countryCode: data.countryCode,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber
    });
  }

  private getSessionOrderDetails(orderId: number) {
    console.log('getSessionOrderDetails');
    return new ConfirmOrderDetails({
      orderId,
      orderDate: new Date(),
      destinationType: 'Residential',
      shippingCarrier: 'USPS - Priority Mail'
    });
  }

  private getSessionLineItems() {
    console.log('getSessionLineItems');
    return this.shoppingCartService.getItems();
  }

  private calculateShippingTotal(cart: CartItem[], srcPostalCode: string, destPostalCode: string) {
    console.log('calculateShippingTotal');
    this.processing = true;
    const orderShipping = this.createOrderShipping(cart, srcPostalCode, destPostalCode);

    this.userService.getUSPSShippingCost(orderShipping)
      .pipe(take(1))
      .subscribe(data => {
        this.sessionOrder.shippingTotal = data.shippingAmount;
        this.sessionOrder.orderTotal = this.sessionOrder.subTotal + this.sessionOrder.shippingTotal + this.sessionOrder.salesTax;
        this.processing = false;
      });
  }

  private createOrderShipping(cart: CartItem[], srcPostalCode: string, destPostalCode: string) {
    console.log('createOrderShipping');
    return new OrderShipping({
      sourcePostalCode: srcPostalCode,
      destPostalCode,
      totalWeight: this.calculateTotalWeight(cart)
    });
  }

  private calculateTotalWeight(cart: CartItem[]) {
    console.log('calculateTotalWeight');
    let total = 0;
    cart.forEach(i => {
      total += (i.noShippingFlag !== true ? (i.quantity * Number(i.weight)) : 0);
    });

    return total;
  }

  private calculateSalesTaxes(billTo: ConfirmBillTo) {
    console.log('calculateSalesTaxes');
    const salesTaxRate = this.salesTaxRate / 100;
    return billTo.stateCode === environment.tfcPostalCode ? Number(this.sessionOrder.subTotal) * salesTaxRate : 0;
  }

  private calculateSubTotal(lineItems: CartItem[]) {
    console.log('calculateSubTotal');
    let subTotal = 0;
    lineItems.forEach(item => {
      subTotal += (item.quantity * item.unitPrice);
    });
    return subTotal;
  }
}
