<div class="container" *ngIf="showCategories">
  <div class="row">
    <div class="col admin-instructions title-bar">
      <h3>Textilefabric Categories</h3>
      <p>Below is a list of categories.  Click on the Edit button to edit the selected category and subcategories.</p>
    </div>
  </div>
  <div class="row">
      <!-- Begin Order List Area -->
      <div class="col">
        <p-table [value]="categories" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
              <tr>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Total Subcategories</th>
                  <th>Action</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-c>
              <tr>
                  <td>{{ c.name }}</td>
                  <td><img src="{{ imageSource }}/{{ c.imagePath }}" alt="Category Image" width="100" class="shadow-4" /></td>
                  <td>{{ c.children.length }}</td>
                  <td>
                      <button class="btn btn-success" [routerLink]="['/admin/categories', c.id]"><i class="fa fa-check"></i>&nbsp;Edit</button>
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td colspan="4">
                <form [formGroup]="categoryForm" (ngSubmit)="submit()">
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="name" placeholder="Enter parent category name" />
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="submit">Add</button>
                    </div>
                  </div>
                </form>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">
                In total there are {{categories ? categories.length : 0 }} categories.
            </div>
        </ng-template>
        </p-table>
      </div>
      <!-- End Order List Area -->
    </div>
  </div>
<!-- </div> -->
