
export class AdminCategory {
  id: number;
  name: string;
  imagePath: string;
  displayOrder: number;
  activeFlag: boolean;
  isEditMode: boolean;
  imageFileData: string;
  imageType: string;
  children: AdminCategory[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
