import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from './../../../../environments/environment';

import { AdminCategory } from './../../../models/admin-category.model';
import { Product } from './../../../models/product.model';

import { AdminService } from './../../../services/site/admin.service';
import { CategoryService } from './../../../services/site/category.service';
import { TokenService } from './../../../services/auth/token.service';

@Component({
  selector: 'app-admin-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class AdminProductsComponent implements OnInit {

  products: Product[] = [];
  categories: AdminCategory[] = [];
  subcategories: AdminCategory[] = [];
  categoryId: Number = 0;
  subCategoryId: Number = 0;
  selectedCategory: AdminCategory;
  selectedSubcategory: AdminCategory;
  displaySubcategories: boolean = false;
  imageSource = `${environment.awsUrl}/products`;
  
  constructor(
    private readonly adminService: AdminService,
    private readonly categoryService: CategoryService,
    private readonly tokenService: TokenService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.tokenService.isAdmin()) {
      this.router.navigate(['/home']);
    }
    this.getCategories();
  }

  getProducts() {
    this.adminService.getProducts()
      .subscribe(data => {
        this.products = data;
      });
  }

  getProductsByCategory(categoryId: Number) {
    this.adminService.getProductsByCategory(categoryId)
      .subscribe(data => {
         this.products = data;
       });
  }

  getCategories() {
    console.log('getCategories');
    this.categoryService.getAdminCategories()
      .subscribe(data => {
         this.categories = data;
         this.categoryId = this.categories[0].id;
         this.selectedCategory = this.categories[0];
         if (this.categories[0].children.length > 0) {
           this.subCategoryId = this.categories[0].children[0].id;
           this.selectedCategory = this.categories[0];
           this.selectedSubcategory = this.categories[0].children[0];
           this.displaySubcategories = true;
           this.getProductsByCategory(this.selectedCategory.id);
         } else {
          this.displaySubcategories = false;
          this.getProductsByCategory(this.selectedCategory.id);
         }
         
         console.log('categories:' + JSON.stringify(this.categories)); 
       });
  }

  selectCategory($event) {
    console.log('selecteCategory');
    this.selectedCategory = $event.value;
    this.categoryId = this.selectedCategory.id;
    this.subCategoryId = 0;
    this.subcategories = this.selectedCategory.children;
    if (this.subcategories.length > 0) {
      this.displaySubcategories = true;
      this.selectedSubcategory = this.selectedCategory.children[0];
      this.subCategoryId = this.selectedSubcategory.id;
      this.getProductsByCategory(this.subCategoryId); 
    } else {
      this.displaySubcategories = false;
      this.getProductsByCategory(this.categoryId);
    }
    
  }

  selectSubcategory($event) {
    console.log('selecteSubcategory');
    this.selectedSubcategory = $event.value;
    this.subCategoryId = this.selectedSubcategory.id;
    this.getProductsByCategory(this.subCategoryId);
    
  }

}
