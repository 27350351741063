import { Component, Inject, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imageSource = `${environment.awsUrl}/videos`;
  domain = '';
  swatchUrl = environment.swatchUrl;
  swatchDomain = environment.swatchDomain;

  config = {
    fade: true,
    alwaysOn: false,
    neverOn: false,

    // fill
    fill: true,
    fillColor: '#ffffff',
    fillOpacity: 0.4,

    // stroke
    stroke: true,
    strokeColor: '#cc66ff',
    strokeOpacity: 1,
    strokeWidth: 8,

    // shadow:
    shadow: true,
    shadowColor: '#000000',
    shadowOpacity: 0.8,
    shadowRadius: 10
  };

  constructor(@Inject(DOCUMENT) private document: any,
        private  jwtHelper: JwtHelperService, private router: Router) {
          this.domain = this.document.location.hostname;
          console.log('domain: ' + this.domain);
  }

  ngOnInit(): void {
    if (this.domain === 'www.getyourswatchkit.com') {
      window.location.href = this.swatchUrl;
    }
  }

  isUserAuthenticated() {
    const token = sessionStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }
    else {
      return false;
    }
  }

}
