import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, shareReplay } from 'rxjs/operators';

import { AdminAccount } from './../../models/admin-account.model';
import { AdminCategory } from 'src/app/models/admin-category.model';
import { AdminOrder } from './../../models/admin-order.model';
import { AdminUser } from './../../models/admin-user.model';
import { AdminPaginatedUser } from 'src/app/models/admin-paginated-user.model';
import { Category } from './../../models/category.model';
import { Order } from './../../models/order.model';
import { OrderStatus } from './../../models/order-status.model';
import { OrderUpdate } from './../../models/order-update.model';
import { Product } from './../../models/product.model';
import { ProductUpdate } from './../../models/product-update.model';
import { Media } from '../../models/media.model';
import { MediaType } from '../../models/media-type.model';

import { ErrorService } from './../utility/error.service';
import { RestService } from './../base/rest.service';


@Injectable({
  providedIn: 'root'
})
export class AdminService extends RestService {

  endpointUrl: string;
  orders$: Observable<AdminOrder[]>;
  account: AdminAccount;

  constructor(
    private http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    super(http, errorService);
    this.endpointUrl = '/api/Admin';
  }

  // Orders methods

  public getOrders(statusCode: string): Observable<AdminOrder[]> {
    const url = `${this.endpointUrl}/orders?statusCode=${statusCode}`;

    return this.get<AdminOrder[]>(url)
      .pipe(
        shareReplay()
      );
  }

  public getOrder(orderId: number): Observable<Order> {
    const url = `${this.endpointUrl}/orders/${orderId}`;

    return this.get<Order>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getOrderStatuses(): Observable<OrderStatus[]> {
    const url = `${this.endpointUrl}/orderstatuses`;

    return this.get<OrderStatus[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public updateOrderStatus(orderStatus: OrderUpdate): Observable<OrderUpdate> {
    const url = `${this.endpointUrl}/orders?orderId=${orderStatus.orderId}`;

    return this.put<OrderUpdate>(url, orderStatus)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }


  // Users methods

  public getUsers(): Observable<AdminUser[]> {
    const url = `${this.endpointUrl}/users`;

    return this.get<AdminUser[]>(url)
      .pipe(
        shareReplay()
      );
  }

  public getPaginatedUsers(pageNumber: number, pageSize: number): Observable<AdminPaginatedUser> {
    const url = `${this.endpointUrl}/users/paginated?page=${pageNumber}&pageSize=${pageSize}`;

    return this.get<AdminPaginatedUser>(url)
      .pipe(
        shareReplay(),
        catchError(this.errorService.handleError)
      );
  }

  public searchPaginatedUsers(pageNumber: number, pageSize: number, searchText: string): Observable<AdminPaginatedUser> {
    const url = `${this.endpointUrl}/users/paginated/search?page=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`;

    return this.get<AdminPaginatedUser>(url)
      .pipe(
        shareReplay(),
        catchError(this.errorService.handleError)
      );
  }

  public getUser(userId: number): Observable<AdminUser> {
    const url = `${this.endpointUrl}/users/${userId}`;

    return this.get<AdminUser>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getUserOrders(userId: number): Observable<AdminOrder[]> {
    const url = `${this.endpointUrl}/users/${userId}/orders`;

    return this.get<AdminOrder[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getUserAccount(userId: number): Observable<AdminAccount> {
    const url = `${this.endpointUrl}/users/${userId}/account`;

    return this.get<AdminAccount>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public updateUserAccount(account: AdminAccount): Observable<AdminAccount> {
    const url = `${this.endpointUrl}/users/${account.accountId}/account`;
    console.log('account: ' + JSON.stringify(account));
    return this.put<AdminAccount>(url, account)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  // Products methods

  public getProducts(): Observable<Product[]> {
    const url = `${this.endpointUrl}/products`;

    return this.get<Product[]>(url)
      .pipe(
        shareReplay()
      );
  }

  public getProductsByCategory(categoryId: Number): Observable<Product[]> {
    const url = `${this.endpointUrl}/products/categories/${categoryId}`;

    return this.get<Product[]>(url)
      .pipe(
        shareReplay()
      );
  }

  public getProduct(productId: number): Observable<Product> {
    const url = `${this.endpointUrl}/products/${productId}`;

    return this.get<Product>(url)
      .pipe(
        shareReplay(),
        catchError(this.errorService.handleError)
      );
  }

  

  public saveProduct(productUpdate: ProductUpdate): Observable<Product> {
    console.log('productUpdate: ' + JSON.stringify(productUpdate));
    const url = `${this.endpointUrl}/products`;

    return this.post<Product>(url, productUpdate)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public saveCategory(categoryUpdate: AdminCategory): Observable<Category> {
    console.log('categoryUpdate: ' + JSON.stringify(categoryUpdate));
    const url = `${this.endpointUrl}/categories`;

    return this.post<Category>(url, categoryUpdate)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  // Media methods

  public getMedia(mediaId: number): Observable<Media> {
    const url = `${this.endpointUrl}/medias/${mediaId}`;

    return this.get<Media>(url)
      .pipe(
        shareReplay(),
        catchError(this.errorService.handleError)
      );
  }

  public getMediaTypes(): Observable<MediaType[]> {
    const url = `${this.endpointUrl}/mediaTypes`; 

    return this.get<MediaType[]>(url)
      .pipe(
        shareReplay(),
        catchError(this.errorService.handleError)
      );
  }

  public listMedias(): Observable<Media[]> {
    const url = `${this.endpointUrl}/medias?mediaTypeId=1`; 

    return this.get<Media[]>(url)
      .pipe(
        shareReplay(),
        catchError(this.errorService.handleError)
      );
  }

  public createMedia(media: Media): Observable<Media> {
    console.log('videoUpdate: ' + JSON.stringify(media));
    const url = `${this.endpointUrl}/medias`;

    return this.post<Media>(url, media)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public updateMedia(media: Media): Observable<Media> {
    console.log('media: ' + JSON.stringify(media));
    const url = `${this.endpointUrl}/medias/${media.id}`;

    return this.put<Media>(url, media)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
  
}
