<div class="container">
  <div class="row">
    <div class="col-sm-12 admin-instructions title-bar">
      <h3>User List</h3>
      <p>Below is a list of website users. Click on the user name to view the user demographics and order list.  Type any text
        that is displayed in the grid to filter the search results for the selected text.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="search-area pull-right">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText [(ngModel)]="searchText" />
          <p-button label="Search" (onClick)="searchUsers()"></p-button>
        </span>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="content-area">
        <!-- <table datatable dtOptions="dtOptions" dtTrigger="dtTrigger" class="table table-condensed table-striped row-border hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
          <tr *ngFor="let u of users">
              <td><a [routerLink]="['/admin/users', u.userID]">{{ u.lastName }}, {{ u.firstName }}</a></td>
              <td>{{ u.emailAddress }}</td>
              <td>{{ u.address1 }}, {{ u.city }}, {{ u.stateCode }} {{ u.postalCode }}</td>
            </tr>
          </tbody>
        </table> -->
        
        <p-table [value]="users" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
              <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Address</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-u>
              <tr>
                <td><a [routerLink]="['/admin/users', u.userID]">{{ u.lastName }}, {{ u.firstName }}</a></td>
                <td>{{ u.emailAddress }}</td>
                <td>{{ u.address1 }}, {{ u.city }}, {{ u.stateCode }} {{ u.postalCode }}</td>
              </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">
                In total there are {{ totalRecords }} users.
            </div>
        </ng-template>
      </p-table>
      <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]"></p-paginator>

      </div>
    </div>
  </div>
</div>
