
import { ResetPasswordResponse } from './../../models/reset-password-response.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, shareReplay } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

import { ConfirmOrder } from './../../models/confirm-order.model';
import { Country } from './../../models/country.model';
import { DocumentResponse } from './../../models/document-response.model';
import { Media } from './../../models/media.model';
import { OrderCalculation } from './../../models/order-calculation.model';
import { OrderShipping } from './../../models/order-shipping.model';
import { PostalCodeResponse } from './../../models/postalcode-response.model';
import { StateProvince } from '../../models/state-province.model';
import { ShippingCarrier } from './../../models/shipping-carrier.model';
import { ShippingMethod } from './../../models/shipping-method.model';
import { ShippingResponse } from './../../models/shipping-response.model';
import { StripeRequest } from './../../models/stripe-request.model';
import { StripeCustomerToken } from './../../models/stripe-customer-token.model';
import { StripeChargeResults } from './../../models/stripe-charge-results.model';
import { UserRegistration } from './../../models/user-registration.model';
import { User } from './../../models/user.model';

import { ErrorService } from './../utility/error.service';
import { PasswordChangeResponse } from 'src/app/models/password-change-response.model';
import { PasswordChange } from 'src/app/models/password-change.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  endpointUrl: string;
  orderShipping: OrderShipping;

  constructor(
    private http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    this.endpointUrl = `${environment.apiUrl}/api/User`;
  }

  public saveRegistration(registration: UserRegistration): Observable<UserRegistration> {
    const url = `${this.endpointUrl}/registration`;

    return this.http.post<UserRegistration>(url, registration)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public saveStripeCharge(req: StripeRequest): Observable<StripeChargeResults> {
    const url = `${this.endpointUrl}/orders/charge`;

    return this.http.post<StripeChargeResults>(url, req)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public updateCustomerToken(accountId: number, req: StripeCustomerToken): Observable<StripeCustomerToken> {
    const url = `${this.endpointUrl}/${accountId}/customerToken`;

    return this.http.put<StripeCustomerToken>(url, req)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public lookupToken(token: string): Observable<PasswordChangeResponse> {
    const url = `${this.endpointUrl}/lookup/${token}`;

    return this.http.get<PasswordChangeResponse>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public updatePassword(emailAddress: string, newPassword: string): Observable<PasswordChangeResponse> {
    const passwordChange = new PasswordChange({ emailAddress: emailAddress, newPassword: newPassword });
    const url = `${this.endpointUrl}/updatepassword`;

    return this.http.post<PasswordChangeResponse>(url, passwordChange)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getCountries(): Observable<Country[]> {
    const url = `${this.endpointUrl}/countries`;

    return this.http.get<Country[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getStates(): Observable<StateProvince[]> {
    const url = `${this.endpointUrl}/states`;

    return this.http.get<StateProvince[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getShippingCarriers(): Observable<ShippingCarrier[]> {
    const url = `${this.endpointUrl}/carriers`;

    return this.http.get<ShippingCarrier[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getShippingMethods(): Observable<ShippingMethod[]> {
    const url = `${this.endpointUrl}/shippingMethods`;

    return this.http.get<ShippingMethod[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getUSPSShippingCost(dto: OrderShipping) {
    const url = `${this.endpointUrl}/shippingCost/usps`;

    return this.http.post<ShippingResponse>(url, dto)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public resetPassword(emailAddress: string) {
    const url = `${this.endpointUrl}/resetpassword`;

    const data = {
      emailAddress
    };

    return this.http.post<ResetPasswordResponse>(url, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public checkPostalCode(postalCode: string, destStateCode: string) {
    const url = `${this.endpointUrl}/checkzipcode?zipCode=${postalCode}&destStateCode=${destStateCode}`;

    return this.http.get<PostalCodeResponse>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public calculateOrderTotals(data: ConfirmOrder) {
    const url = `${this.endpointUrl}/orders/totals`;

    return this.http.post<OrderCalculation>(url, data)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getDocuments(documentTypeId: number): Observable<DocumentResponse[]> {
    const url = `${this.endpointUrl}/documents?documentTypeId=${documentTypeId}`;

    return this.http.get<DocumentResponse[]>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public listMedias(): Observable<Media[]> {
    const url = `${this.endpointUrl}/medias?mediaTypeId=1`; 

    return this.http.get<Media[]>(url)
      .pipe(
        shareReplay(),
        catchError(this.errorService.handleError)
      );
  }
}
