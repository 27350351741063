<div class="container">
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Contact Textile Fabric Consultants, Inc</h5>
        <p class="card-text">You can contact our sales, marketing, and technical support departments regarding Textile Fabric Consultants in several different ways. Our normal business hours are Monday-Friday, 8:00am - 4:00 pm, Central Time.</p>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <b>Phone: </b>(800) 210-9394<br /><br />
            <b>Fax: </b>(615) 459-3744<br /><br />
            <b>Email: </b> General Information or suggestions<br/>
            <a href="mailto:textilefc@textilefabric.com">textilefc&#64;textilefabric.com</a><br /><br />
            <b>Orders:</b><br />
            <a href="mailto:tfcorders@textilefabric.com">tfcorders&#64;textilefabric.com</a><br /><br />
            <b>Address:</b><br />
            Textile Fabric Consultants, Inc<br />
            521 Huntly Industrial Drive<br />
            Smyrna, TN  37167<br /><br />
          </div>
          <div class="col-sm-12 col-md-6">
            <form [formGroup]="notifyForm" (ngSubmit)="submit()">
              <h4>Email Us</h4><br />
              <label>Name:</label><br />
              <input type="text" class="form-control" formControlName="fullName"><br />
              <div *ngIf="f.fullName.touched && f.fullName.invalid" class="alert alert-danger">
                <div *ngIf="f.fullName.errors.required">Full Name is required.</div>
                <div *ngIf="f.fullName.errors.minlength">Full Name should be at least 3 characters.</div>
                <div *ngIf="f.fullName.errors.validFullName">Full Name cannot be blank.</div>
              </div>
              <label>Email Address:</label><br />
              <input type="text" class="form-control" formControlName="emailAddress" required /><br />
              <div *ngIf="f.emailAddress.touched && f.emailAddress.invalid" class="alert alert-danger">
                <div *ngIf="f.emailAddress.errors.required">Email Address is required.</div>
                <div *ngIf="f.emailAddress.errors.email">Email Address is not valid.</div>
              </div>
              <label>Message:</label>
              <textarea class="form-control" rows="5" cols="80" formControlName="message" required></textarea>
              <div *ngIf="f.message.touched && f.message.invalid" class="alert alert-danger">
                <div *ngIf="f.message.errors.required">Message content is required.</div>
                <div *ngIf="f.message.errors.minlength">Message content should be at least 3 characters.</div>
                <div *ngIf="f.message.errors.validMessage">Message content is not valid.</div>
              </div>
              <br /><br />
              <button class="btn btn-primary btn-block" type="submit" [disabled]="notifyForm.invalid"><i class="fa fa-envelope"></i>&nbsp;Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
