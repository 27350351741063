<div class="container">
  <div class="col-sm-12">
    <div class="card-group">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">TextileFabric Reset Password</h5>
          <p>Enter the email address associated with your account below and click the Submit button.  You will receive a reset password email with a link that
            you will need to click on to complete the reset password request.  Please check your Inbox or Junk mail box for the
            password reset email.  You will have 24 hours to reset your password once you receive the email.  Please allow a few minutes
            to receive the email.
          </p>
          <form [formGroup]="resetForm" (ngSubmit)="submit()">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Enter email address" aria-label="EmailAddress" aria-describedby="basic-addon1" formControlName="email" />
            </div>
            <div class="pull-right">
              <button type="submit" class="btn btn-primary"><i class="fa fa-check"></i>&nbsp;Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>