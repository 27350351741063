<footer class="footer bg-light bg-white fg-black">
  <div class="container">
    <div class="row">
        <div class="text-center footer-image">
            <img src="/assets/images/footer.png" alt="footer" />
        </div>
    </div>
      <div class="row">
          <div class="col-sm-12 col-md-6 my-auto h-100 text-center text-lg-left">
              <ul class="list-inline mb-2">
                  <li class="list-inline-item"><a [routerLink]="['/about']">About</a></li>
                  <li class="list-inline-item"><span>⋅</span></li>
                  <li class="list-inline-item"><a [routerLink]="['/contact']">Contact Us</a></li>
                  <li class="list-inline-item"><span>⋅</span></li>
                  <li class="list-inline-item"><a [routerLink]="['/privacy']">Privacy Policy</a></li>
                  <li class="list-inline-item"><span>⋅</span></li>
                  <li class="list-inline-item"><a [routerLink]="['/returns']">Return Policy</a></li>
                  <li class="list-inline-item"><span>⋅</span></li>
                  <li class="list-inline-item"><a [routerLink]="['/terms']">Terms of &nbsp;Use</a></li>
                  
              </ul>
          </div>
          <div class="col-sm-12 col-md-6 my-auto h-100 text-right text-lg-right">
              <ul class="list-inline mb-0">
                  <li class="list-inline-item"><a href="https://www.facebook.com/textile.fabric" target="_blank"><i class="fa fa-facebook fa-2x fa-fw"></i></a></li>
                  <li class="list-inline-item"><a href="#"><i class="fa fa-twitter fa-2x fa-fw"></i></a></li>
                  <li class="list-inline-item"><a href="#"><i class="fa fa-instagram fa-2x fa-fw"></i></a></li>
              </ul>
          </div>
      </div>
  </div>
</footer>
