<div class="container">
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <div class="col-sm-12">
            <h5 class="card-title alert alert-info">Privacy Policy</h5>
            <div class="card-text">
              <b>Introduction</b><br/>
              TextileFabric.com is dedicated to safeguarding the privacy and security of our customers' information, particularly as we engage in the sale of fabric swatch kits and tools designed for student use. This Privacy Policy outlines our practices concerning the collection, use, protection, and disclosure of your information through your interactions with our website and services.
              <br/><br/>
              <b>Information Collection</b><br/>
              TextileFabric.com collects information in order to enhance our services and your customer experience. The types of information we gather are:

              <br/><br/><b>Personal Information Provided by You</b><br/>
              <ul>
              <li><i><u>Account Registration:</u></i> When creating an account, you will be prompted to provide personal details such as your name, email address, telephone number, and payment information.</li>
              <li><i><u>Purchases and Service Use:</u></i> Information related to your transactions and use of our services, including purchase history.</li>
              </ul>
              
              <br/><b>Automatically Collected Information</b><br/>
              <ul>
              <li><i><u>Device and Usage Information:</u></i> Details about how you access and use our services, including your IP address, browser type, and device information.</li>
              <li><i><u>Location Data:</u></i> Geographic location information derived from your device or network.</li>
              <li><i><u>Cookies and Tracking Technologies:</u></i> Data collected via cookies, web beacons, and other tracking technologies to understand usage patterns and preferences.</li>
              </ul>

              <br/><b>Use of Collected Information</b><br/>
              The information we collect is utilized to:
              <ul>
              <li>Deliver, improve, and customize our services.</li>
              <li>Process transactions and maintain your account.</li>
              <li>Communicate with you about your account or transactions, and send you informational or promotional messages.</li>
              <li>Ensure the security and integrity of our services.</li>
              </ul>

              <br/><b>Information Sharing and Disclosure</b><br/>
              TextileFabric.com respects your privacy and only shares your personal information under limited circumstances:
              <ul>
              <li><u><i>With Your Consent:</i></u> We will share personal information outside of TextileFabric.com when we have your explicit consent.</li>
              <li><u><i>Service Providers:</i></u> Information may be shared with trusted third-party service providers to process information on our behalf, subject to confidentiality agreements.</li>
              <li><u><i>Legal Obligations:</i></u> We may disclose information if required by law or in response to legal requests by government entities.</li>
              </ul>
              
              <br/><b>Security Measures</b><br/>
              We are committed to protecting the security of your personal information through:

              <br/><br/><b>Use of encryption for data transmission.</b><br/>
              Implementation of physical and electronic security measures.
              Limiting access to personal information to employees and contractors who need it to perform their job functions.
              
              
              <br/><br/><b>Your Rights and Choices</b><br/>
              You have the right to access, update, or delete your personal information. TextileFabric.com provides tools and account settings to access, correct, delete, or modify the personal information you provided to us.

              <br/><br/><b>Policy Updates</b><br/>
              This Privacy Policy may be updated periodically to reflect changes in our practices. Significant changes will be communicated through our website or by direct notification.

              <br/><br/><b>Limitation of Liability</b><br/>
              TextileFabric.com shall not be liable for any indirect, incidental, special, or consequential damages arising from the use of our services or the purchase of our products.

              <br/><br/><b>Contact Information</b><br/>
              For questions or concerns regarding this Privacy Policy, please contact us at:

              Email: [Insert Email Address]
              Postal Address: [Insert Address]

              <br/>
              By engaging with our website and services, you acknowledge and agree to the terms outlined in this Privacy Policy.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
