import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from './../../../../environments/environment';

import { AdminCategory } from './../../../models/admin-category.model';
import { Category } from '../../../models/category.model';

import { CategoryService } from '../../../services/site/category.service';
import { TokenService } from './../../../services/auth/token.service';

@Component({
  selector: 'app-admin-categories',
  templateUrl: './admin-categories.component.html',
  styleUrls: ['./admin-categories.component.scss']
})
export class AdminCategoriesComponent implements OnInit {

  //categories$: Observable<Category[]>;
  categories: Category[] = [];
  category: AdminCategory;
  categoryForm: FormGroup;
  showCategories = false;
  submitted = false;
  //imageSource = `/assets/images`;
  imageSource = `${environment.awsUrl}/categories`;
  
  constructor(
    private readonly categoryService: CategoryService,
    private readonly fb: FormBuilder,
    private readonly tokenService: TokenService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.canViewAdmin()) {
      this.router.navigate(['/home']);
    } else {
      this.getCategories();
      this.categoryForm = this.createCategoryForm();
    }
  }

  get f() { return this.categoryForm.controls; }

  createCategoryForm() {
    return this.fb.group({
      name: ['', Validators.required]
    });
  }

  canViewAdmin() {
    return this.tokenService.isAdmin();
  }

  getCategories() {
    console.log('getCategories');
    this.categoryService.getCategories()
      .subscribe(data => {
         this.categories = data;
         this.showCategories = true;
         console.log('refreshed data');
       });
  }

  submit() {
    this.submitted = true;

    if (this.categoryForm.invalid) {
      console.log('Invalid category form');
      return;
    }

    console.log('Creating category');
    const category = new AdminCategory({ id: 0, name: this.f.name.value, imagePath: '', activeFlag: true });

    this.categoryService.createCategory(category)
      .subscribe(d => {
        this.categoryForm = this.createCategoryForm();
        this.getCategories();
      });
  }

}
