import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Account } from '../../models/account.model';
import { Auth } from './../../models/auth.model';

import { TokenService } from './../../services/auth/token.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<string>;
  public currentUser: Observable<string>;
  private currentUserSecLvlSubject: BehaviorSubject<string>;
  public currentUserSecLvl: Observable<string>;

  auth: Auth;
  endpointUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private jwtHelper: JwtHelperService
  ) {
      this.currentUserSubject = new BehaviorSubject<string>(this.tokenService.getUserName());
      this.currentUser = this.currentUserSubject.asObservable();
      this.currentUserSecLvlSubject = new BehaviorSubject<string>(this.tokenService.getSecurityLevel());
      this.currentUserSecLvl = this.currentUserSecLvlSubject.asObservable();
  }

  public get currentUserValue(): string {
    return this.currentUserSubject.value;
  }

  public get currentUserSecLvlValue(): string {
    return this.currentUserSecLvlSubject.value;
  }

  login(username: string, password: string) {

    this.auth = new Auth({
      emailAddress: username,
      password
    });

    return this.http.post<any>(`${this.endpointUrl}/api/authentication/requesttoken`, this.auth)
        .pipe(map(resp => {
            // // login successful if there's a jwt token in the response
            // if (resp && resp.token) {
            //     // store user details and jwt token in session storage to keep user logged in between page refreshes
            //     this.currentUserSubject.next(this.tokenService.getUserName());
            // } else {
            //   resp.name = 'Login';
            // }
            this.tokenService.setCurrentUserData(resp);
            const userName = this.tokenService.getUserName();
            const userSecLvl = this.tokenService.getSecurityLevel();
            console.log('userName: ' + userName);
            console.log('seclvl: ' + userSecLvl);
            this.currentUserSubject.next(userName);
            this.currentUserSecLvlSubject.next(userSecLvl);

            return resp;
        }));
  }

  logout() {
      // remove user from local storage to log user out
      // sessionStorage.clear();
      sessionStorage.removeItem("jwt");
      this.currentUserSubject.next(null);
      this.currentUserSecLvlSubject.next(null);
  }

  isUserAuthenticated() {
    const token = sessionStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    } else {
      return false;
    }
  }

}

