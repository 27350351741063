<div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="col-sm-12">
              <h5 class="card-title">Find Your School</h5>
              <p class="card-text">Coming soon!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  