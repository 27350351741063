import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  public getUserName(): string {
    console.log('token-getUserName');
    const currentUser = sessionStorage.getItem('currentUser');
    let userName = null;
    if (currentUser !== null) {
      const user = JSON.parse(currentUser);
      if (user.name !== null) {
        userName = user.name;
      } else {
        userName = 'User';
      }
    }
    return userName;
  }

  public getToken(): string {
    const currentUser = sessionStorage.getItem('currentUser');
    let token = null;
    if (currentUser !== null) {
      const user = JSON.parse(currentUser);
      if (user.token !== null) {
        token = user.token;
      }
    }
    return token;
  }

  public getSecurityLevel(): string {
    const currentUser = sessionStorage.getItem('currentUser');
    let securityLevel = '';
    if (currentUser !== null) {
      const user = JSON.parse(currentUser);
      if (user.securityLevel !== null) {
        securityLevel = user.securityLevel;
      }
    }
    return securityLevel;
  }

  public isAdmin(): boolean {
    const currentUser = sessionStorage.getItem('currentUser');

    if (currentUser === undefined || currentUser === null) {
      return false;
    }

    const user = JSON.parse(currentUser);
    if (user.securityLevel !== null && user.securityLevel.toLowerCase() === 'admin') {
      return true;
    }

    return false;
  }

  public getRedirectUrl(): string {
    return sessionStorage.getItem('redirectUrl');
  }

  public setCurrentUserData(data: any) {
    sessionStorage.setItem('currentUser', JSON.stringify(data));
  }
}
