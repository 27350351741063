import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

import { Order } from './../../models/order.model';
import { BillTo } from './../../models/bill-to.model';
import { ShipTo } from './../../models/ship-to.model';
import { CartItem } from './../../models/cart-item.model';
import { OrderShipping } from './../../models/order-shipping.model';
import { ConfirmOrder } from './../../models/confirm-order.model';
import { ConfirmBillTo } from './../../models/confirm-bill-to.model';
import { ConfirmShipTo } from './../../models/confirm-ship-to.model';
import { ConfirmOrderDetails } from './../../models/confirm-order-details.model';
import { ConfirmLineItem } from './../../models/confirm-line-item.model';

import { ErrorService } from './../utility/error.service';
import { RestService } from './../base/rest.service';
import { ShoppingCartService } from './../../services/site/shopping-cart.service';
import { UserService } from './../../services/site/user.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends RestService {

  endpointUrl: string;
  sessionOrder = new ConfirmOrder();
  originatingPostalCode = environment.tfcPostalCode;
  salesTaxRate = Number(environment.salesTaxRate);

  constructor(
    private http: HttpClient,
    private readonly errorService: ErrorService,
    private readonly shoppingCartService: ShoppingCartService,
    private readonly userService: UserService
  ) {
    super(http, errorService);
    this.endpointUrl = '/api/User/orders';
  }

  public createOrder(sessionOrder: ConfirmOrder): Observable<Order> {
    const url = `${this.endpointUrl}`;

    return this.post<Order>(url, sessionOrder)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public updateOrder(order: Order): Observable<Order> {
    const url = `${this.endpointUrl}`;

    return this.put<Order>(url, order)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  public getOrder(orderId: number): Observable<Order> {
    const url = `${this.endpointUrl}/${orderId}`;

    return this.get<Order>(url)
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

}
