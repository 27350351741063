import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { Auth } from '../../models/auth.model';
import { Account } from './../../models/account.model';
import { AuthenticationService } from './../../services/auth/authentication.service';
import { LoggerService } from './../../services/utility/logger.service';
import { TokenService } from './../../services/auth/token.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

  currentUser: Account;
  auth: Auth;
  loginForm: FormGroup;
  error = '';
  isLoggedIn: boolean;
  errorMessage: any = '';
  returnUrl: string;
  redirectUrl: string;
  loading = false;
  submitted = false;
  isPasswordShown = false;

  constructor(
    private formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly logger: LoggerService,
    private readonly tokenService: TokenService,
    private readonly toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.createLoginForm();

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/home';
  }

  get f() { return this.loginForm.controls; }

  createLoginForm() {
    return this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  submit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe( data => {
        console.log('login-data: ' + JSON.stringify(data));
        this.tokenService.setCurrentUserData(data);

        if (data.validated) {
          this.redirectUrl = this.tokenService.getRedirectUrl();
          if (this.redirectUrl === undefined || this.redirectUrl === null) {
            console.log('user-data: ' + JSON.stringify(data));
            if (data.securityLevel === 'admin') {
              console.log('redirecting to /admin');
              this.returnUrl = '/admin';
              this.router.navigate([this.returnUrl]);
            } else {
              console.log('redirecting to home');
              this.returnUrl = `/home`;
              console.log('returnUrl: ' + this.returnUrl);
              this.router.navigate([`home`]);
            }
          } else {
            console.log('redirectUrl: ' + this.redirectUrl);
            // sessionStorage.removeItem('redirectUrl');
            this.router.navigate([this.redirectUrl]);
          }
        } else {
          this.error = data.errors[0];
        }

      }, error => {
        console.log('login error: ' + JSON.stringify(error));
        this.error = `Login Error Status: ${error.status} - ${error.statusText}`;
        console.error(error.message);
        this.toastrService.error(`Login Error: ${error.message}`);
        this.loading = false;
      });

  }

  logout(): void {
    this.logger.log('logout method instantiated');
    this.authenticationService.logout();
  }

  togglePasswordShow(): void {
    this.isPasswordShown  = !this.isPasswordShown;
  }

}
