import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { environment } from './../../../environments/environment';

import { Notification } from './../../models/notification.model';
import { EmailAddress } from './../../models/email-address.model';

import { NotificationService } from './../../services/site/notification.service';
import { LoggerService } from './../../services/utility/logger.service';
import { ToastrService } from 'ngx-toastr';

export function ValidateFullName(control: AbstractControl) {
  if (control.value.trim().length === 0) {
    return { validFullName: true };
  }

  return null;
}

export function ValidateMessage(control: AbstractControl) {
  if (control.value.trim().length === 0) {
    return { validMessage: true };
  }

  return null;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  notifyForm: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private readonly loggerService: LoggerService,
    private readonly notificationService: NotificationService,
    private readonly toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.notifyForm = this.createNotifyForm();
  }

  get f() { return this.notifyForm.controls; }

  createNotifyForm() {
    return this.formBuilder.group({
      fullName: ['', [Validators.required, Validators.minLength(3), ValidateFullName]],
      emailAddress: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.minLength(3), ValidateMessage]]
    });
  }

  submit() {
    this.submitted = true;

    if (this.notifyForm.invalid) {
      return;
    }

    const notification = this.createNotification();

    this.notificationService.SendNotification(notification)
      .subscribe(data => {
        this.notifyForm = this.createNotifyForm();
        this.toastrService.success('Successfully sent contact form to TextileFabric Consultants');
      });
  }

  createNotification() {
    const fromAddress = new EmailAddress({
      emailAddress: this.f.emailAddress.value,
      fullName: this.f.fullName.value
    });
    const toAddress = new EmailAddress({
      emailAddress: environment.fromAddress,
      fullName: 'TextileFabric Contact Form'
    });
    const body = 'Name: ' + this.f.fullName.value + '<br/><br/>Email: ' + this.f.emailAddress.value + '<br/><br/>' + this.f.message.value;

    return new Notification({
      fromAddress,
      toAddress,
      subject: 'Textile Fabric Contact Form',
      body
    });
  }

}
