
import { StripeCustomerToken } from './../../models/stripe-customer-token.model';
import {OnInit, Component, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from './../../../environments/environment';

import { ConfirmOrder } from './../../models/confirm-order.model';
import { Order } from './../../models/order.model';
import { StripeRequest } from './../../models/stripe-request.model';

import { OrderService } from './../../services/site/order.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../services/site/user.service';

@Component({
    selector: 'app-stripe-payment',
    templateUrl: './stripe-payment.component.html',
    styleUrls: ['./stripe-payment.component.scss'],
})
export class StripePaymentComponent implements OnInit {

    @Input() sessionOrder: ConfirmOrder;

    order: Order;
    savedOrder: Order;
    sourceToken: string;
    isSubmitted = false;

    constructor(
      private router: Router,
      private orderService: OrderService,
      private toastrService: ToastrService,
      private userService: UserService
    ) { }

    ngOnInit() {
        this.loadStripe();
    }

    loadStripe() {
      if (!window.document.getElementById('stripe-script')) {
        const s = window.document.createElement('script');
        s.id = 'stripe-script';
        s.type = 'text/javascript';
        s.src = 'https://checkout.stripe.com/checkout.js';
        window.document.body.appendChild(s);
      }
    }

    saveOrder() {
      console.log('saveOrder');
      this.isSubmitted = true;
      const currentUser = sessionStorage.getItem('currentUser');
      if (currentUser === undefined || currentUser === null) {
        sessionStorage.setItem('redirectUrl', '/orders/confirmation');
        this.router.navigate(['/login']);
      } else {
        const user = JSON.parse(currentUser);

        this.sessionOrder.userId = user.userID;
        this.sessionOrder.accountId = user.accountID;

        console.log('stripe-payment: Creating order in database');
        console.log('saveOrder-sessionOrder: ' + JSON.stringify(this.sessionOrder));

        this.orderService.createOrder(this.sessionOrder)
          .subscribe(data => {
            console.log('stripe-payment-saveOrder');
            this.savedOrder = data;
            if (this.savedOrder.orderID > 0) {
              console.log('stripe-payment: Proceeding to collect order payment');
              const total1 = Number(this.sessionOrder.orderTotal.toFixed(2));
              console.log('total1: ' + total1);

              const orderTotal = Number(total1);
              console.log('orderTotal: ' + orderTotal);

              this.pay(this.savedOrder.orderID, orderTotal,
                this.sessionOrder.billTo.emailAddress, this.sessionOrder.accountId);
            } else {
              console.log('stripe-payment: Order not saved');
              this.toastrService.error('Order could not be created');
            }
            this.isSubmitted = false;
          }, error => {
            this.toastrService.error('Order Error: ' + error);
            this.isSubmitted = false;
          });
      }

    }

    test() {
      console.log('passed-token: ' + this.sourceToken);
    }

    chargeOrder(orderTotal: number, orderId: number, email: string, token: string) {
      console.log('chargeOrder');
      const stripeRequest = new StripeRequest({
        orderTotal,
        orderID: orderId,
        emailAddress: email,
        sourceToken: token
      });
      console.log('charge-order: orderTotal: ' + orderTotal);
      this.userService.saveStripeCharge(stripeRequest)
        .subscribe(data => {
          console.log('chargeOrder data: ' + JSON.stringify(data));
          if (data.captured) {
            this.toastrService.success('Stripe charge successful');
          } else {
            this.toastrService.error('Stripe charge failed - ' + data.failureMessage);
          }

        });
    }

    updateCustomerToken(accountId: number, token: string) {
      console.log('updateCustomerToken');
      const request = new StripeCustomerToken({
        accountId,
        sourceToken: token
      });

      this.userService.updateCustomerToken(accountId, request)
        .subscribe(data => {
          this.toastrService.success('Updated customer token successfully');
        });

    }

    pay(orderId: number, orderTotal: any, email: string, accountId: number) {
      console.log('pay');
      let submittedForm = false;
      console.log('stripePublicKey: ' + environment.stripePublicKey);
      console.log('pay-orderTotal: ' + orderTotal);
      const handler = (window as any).StripeCheckout.configure({
        key: environment.stripePublicKey,
        locale: 'auto',
        token(token: any) {
          // You can access the token ID with `token.id`.
          // Get the token ID to your server-side code for use.
          console.log('token: ' + JSON.stringify(token));
          this.sourceToken = token.id;
          console.log('stripe-payment-sourceToken: ' + this.sourceToken);
          submittedForm = true;

          const stripeOrder = {
            orderId,
            orderTotal,
            email,
            accountId,
            token: token.id
          };
          console.log('stripeOrder: ' + JSON.stringify(stripeOrder));
          sessionStorage.setItem('stripe-charge', JSON.stringify(stripeOrder));
          window.location.href = '/orders/payment';
        }
      });

      handler.open({
        name: 'TFC Order',
        description: 'Order #' + orderId ,
        amount: Math.round(orderTotal.toFixed(2) * 100),
        closed() {
          console.log('closed payment dialog');
        }
      });


    }
}
