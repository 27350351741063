import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Media } from '../../models/media.model';

import { UserService } from '../../services/site/user.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  medias: Media[];

  constructor(
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.listMedias();
  }

  listMedias() {
    this.userService.listMedias()
      .subscribe(data => {
        this.medias = data;
        console.log('medias: ' + JSON.stringify(data));
      });
  
  }

}
