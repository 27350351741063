<div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="col-sm-12">
              <h5 class="card-title alert alert-info">Return Policy</h5>
              <div class="card-text">
                Written permission must be obtained from a Textile Fabric Consultants team member before sending back any merchandise.   Merchandise 
                returned without permission will be refused and no credit will be given.   We ONLY accept returns within the first 30 days of purchase date.

                A 10% restocking fee is applied to all returned products.  All returned merchandise must be in new condition.  Swatches must be in tact 
                with rubber bands still secured.  Product must contain all components and mounting pages must have no writing on them.  We do not accept 
                products with any missing or damaged parts. 
                <hr/>
                <h4>Return FAQS</h4><br/>
                <b>What if I ordered the wrong kit?</b><br/>
                We encourage you to open your package upon arrival to ensure that it is the correct kit for your class.  We understand that swatch kit 
                ordering options may cause confusion and result in an incorrect purchase.  We will be glad to exchange your kit for the correct kit.  
                Exchanges can be made on new items within 30 days of purchase.  Simply contact us with your order number and you will receive return 
                instructions.  Shipping costs are not refundable.

                <br/><br/><b>What if I drop the class?</b><br/>
                Some items are not returnable.  If an item is not returnable, it will be noted on the product description page.  Items that are eligible 
                for return must be accompanied by a return authorization from our customer service department.  Contact us within 30 days of purchase to 
                receive a return authorization number and return instructions.  The item must be new and unopened.  A 10% restocking fee is applied to 
                returned merchandise unless an exchange is made.  Shipping costs are not refundable.  If you drop the class, ask another student to 
                purchase your kit instead of returning your item.  

                <br/><br/><b>What if my item arrives damaged?</b><br/>
                We encourage you to open your package upon arrival to ensure that it is the correct kit for your class and that no damage occurred 
                during shipment.  Please contact us immediately to discuss options for replacing your damaged item.  

                <br/><br/><b>What if I receive the wrong product?</b><br/>
                We encourage you to open your package upon arrival to ensure that it is the correct kit for your class.  Please contact us immediately 
                to discuss options for exchanging your item.   We will  gladly make sure you have the correct product for your course.

                <hr/>
                <h4>Bookstore Return Policy</h4><br/>
                We do not accept returns for overstock or out-of-print editions.  Please contact us immediately if you receive damaged or missing products.  
                Return Requests may be emailed (<a mailto="textilefc@textilefabric.com"><u>textilefc&commat;textilefabric.com</u></a>) or faxed (615.459.3744) 
                stating the purpose of the return, quantity, and invoice number or evidence of original purchase.  

                Bookstores are asked to direct students directly to Textile Fabric Consultants, Inc. (615.459.7510) for questions regarding missing items from 
                their kit.  If the bookstore automatically replaces the kit or missing items, full reimbursement or replacement may not be approved.
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  