import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Notification } from './../../models/notification.model';

import { ErrorService } from './../utility/error.service';
import { RestService } from './../base/rest.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends RestService {

  endpointUrl: string;

  constructor(
    private http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    super(http, errorService);
    this.endpointUrl = '/api/Notification';
   }

   public SendNotification(notification: Notification)
   {
     const url = `${this.endpointUrl}`;
     return this.post(url, notification)
      .pipe(
        catchError(this.errorService.handleError)
      );
   }
}
