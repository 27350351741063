import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ChildActivationEnd } from '@angular/router';

import { environment } from './../../../../../environments/environment';

import { Category } from './../../../../models/category.model';
import { Product } from './../../../../models/product.model';
import { ProductUpdate } from './../../../../models/product-update.model';

import { AdminService } from './../../../../services/site/admin.service';
import { CategoryService } from './../../../../services/site/category.service';
import { LoadingService } from './../../../../shared/loading/loading.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from './../../../../services/auth/token.service';

import { NgxSpinnerService } from "ngx-spinner";

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { waitForAsync } from '@angular/core/testing';

export class FileInfo
{
  fileName: string;
  fileType: string;
  fileData: string;
}

export interface CombinedCategory
{
  id: number;
  name: string;
}

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  product: Product;
  productForm: FormGroup;
  categories$: Observable<CombinedCategory[]>;
  imageSource = `${environment.awsUrl}/products`;
  submitted = false;
  showProduct = false;
  uploadFile = new FileInfo();

  constructor(
    private readonly adminService: AdminService,
    private readonly categoryService: CategoryService,
    private readonly loadingService: LoadingService,
    private readonly tokenService: TokenService,
    private readonly fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private readonly toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if (!this.tokenService.isAdmin()) {
      this.router.navigate(['/home']);
    }
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
    this.productForm = this.createProductForm();
    this.route.params.subscribe(params => {
      console.log('id: ' + params.id);
      this.getProduct(params.id);
      this.getCategories();
    });
  }

  get f() { return this.productForm.controls; }

   
  getProduct(productId: number) {
    const product$ = this.adminService.getProduct(productId)
      .pipe(
        shareReplay()
      );
    const loadedProduct$ = this.loadingService.showLoaderUntilCompleted(product$);
    loadedProduct$.subscribe(product => {
      this.product = product;
      this.setProductForm(this.product);
      this.showProduct = true;
    });

  }

  getCategories() {
    const categories$ = this.categoryService.getCategories()
      .pipe(
        map(categories => {
          const finalCategories: CombinedCategory[] = [];
          categories.forEach((c) => {
            const cat1: CombinedCategory = { id: c.id, name: c.name };
            finalCategories.push(cat1);
            if (c.children.length > 0) {
              c.children.forEach(child => {
                const cat2: CombinedCategory = { id: child.id, name: cat1.name + ' | ' + child.name };
                finalCategories.push(cat2);
              });
            }
          });
          return finalCategories;
        }),
        shareReplay()
      );
    const loadedCategories$ = this.loadingService.showLoaderUntilCompleted(categories$);
    this.categories$ = loadedCategories$;
  }

  createProductForm() {
    return this.fb.group({
      name: ['', Validators.required],
      description: [''],
      activeFlag: [true],
      weight: [0],
      noShippingFlag: [false],
      shippingAmount: [0],
      priceLabel: [''],
      unitPrice: [0],
      categoryId: [0],
      file: ['']
    });
  }

  setProductForm(product: Product) {
    this.productForm.setValue({
      name: product.name,
      description: product.description,
      activeFlag: product.activeFlag,
      weight: product.weight,
      noShippingFlag: product.noShippingFlag,
      shippingAmount: product.shippingAmount,
      priceLabel: product.priceLabel,
      unitPrice: product.unitPrice,
      categoryId: product.productCategoryID,
      file: ''
    });
  }

  
  submit() {
    this.submitted = true;

    if (this.productForm.invalid) {
      return;
    }

    this.spinner.show();
    const productUpdate = this.createProductUpdate();
    console.log('productUpdate: ' + JSON.stringify(productUpdate));
    this.adminService.saveProduct(productUpdate)
      .subscribe(data => {
        console.log('product: ' + JSON.stringify(data));
        this.product = data;
        this.toastr.success('Successfully saved product');
        this.spinner.hide();
      });

  }

  createProductUpdate() {
    return new ProductUpdate({
      id: this.product.id,
      name: this.f.name.value,
      description: this.f.description.value,
      activeFlag: this.f.activeFlag.value,
      weight: Number(this.f.weight.value),
      noShippingFlag: this.f.noShippingFlag.value,
      shippingAmount: Number(this.f.shippingAmount.value),
      priceLabel: this.f.priceLabel.value,
      unitPrice: Number(this.f.unitPrice.value),
      imagePath: this.uploadFile.fileName === undefined || this.uploadFile.fileName === null ?
        this.product.imagePath :
        this.uploadFile.fileName,
      imageFileType: this.uploadFile.fileType,
      imageFileData: this.uploadFile.fileData,
      productCategoryID: Number(this.f.categoryId.value)
    });
  }

  onFileChange(files: FileList) {

    if (files.length > 0) {
      const file = files[0];
      const fileToUpload = file.name;

      this.uploadFile.fileName = file.name;
      this.uploadFile.fileType = file.type;

      this.getBase64(file);
    }
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.uploadFile.fileData = reader.result.toString();
    };
    reader.onerror = error => {
      console.log('Error: ', error);
      return null;
    };
  }

}
