<div class="container">
  <div coass="row">
    <div class="col">
      <div class="card" *ngIf="showCategory">
        <div class="card-body">
          <h4 class="card-title">Category: {{categoryName}}</h4>
          <div class="instructions">
            <p>Enter the information below to edit the category on the <em><u>Category Detail</u></em> tab.  On the <em><u>Subcategories</u></em> tab, you will maintain Subcategories
              for this parent category.</p>
          </div>
          <mat-tab-group>
            <mat-tab label="Category Detail">
              <div class="category-content">
                <form [formGroup]="parentForm">
                    <div class="d-flex">
                      <div class="p-2 w-50">
                        <img src="{{ imageSource }}/{{imagePath }}" alt="Product Image" /><br/><br/>
                        <div class="upload-data">
                          <input type="file" formControlName="imagePath" (change)="onFileChange($event.target.files)" />
                        </div>
                      </div>
                      <div class="flex-column w-50">
                        <div class="p-2">
                          <div class="form-group">
                            <label>Category Name:</label>
                            <input type="text" class="form-control" formControlName="name" placeholder="Enter category name" />
                          </div>
                        </div>
                        <!-- <div class="p-2 flex-fill">
                          <div class="form-group">
                            <label>Display Order</label>
                            <input type="text" class="form-control" formControlName="displayOrder" placeholder="Enter display order" />
                          </div>
                        </div> -->
                      
                        <div class="p-2">
                          <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" formControlName="activeFlag" />
                            <label class="form-check-label">&nbsp;Is Active Category</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-right">
                      <button class="btn btn-primary" type="submit" (click)="submit()"><i class="fa fa-check"></i>&nbsp;Save</button>
                    </div>
                  
                </form>
              </div>
            </mat-tab>
            <mat-tab label="Subcategories">
              <div class="row">
                <div class="col">
                  <div class="subcat-content">
                    <fieldset>
                      <legend>Subcategories</legend>
                      <div *ngFor="let c of parentCategory.children">
                        <div class="card">
                          <div class="card-body">
                            <div class="row">
                              <div *ngIf="!c.isEditMode" style="width: 100%">
                                <div class="col-sm">{{ c.name }}</div>
                                <div class="col-sm text-right">
                                  <button class="btn btn-primary" (click)="toggleEdit(c)"><i class="fa fa-pencil"></i>&nbsp;Edit</button>
                                </div>
                              </div>
                              <div *ngIf="c.isEditMode" style="width: 100%">
                                <div class="col-sm">
                                  <div class="row mb-2">
                                    <form class="form-inline" [formGroup]="categoryForm">
                                      <div class="col-sm">
                                        <div class="form-group mb-2">
                                          <label>Subcategory Name</label>
                                          <input type="text" class="form-control" formControlName="name" placeholder="Enter subcategory name" />
                                        </div>
                                      </div>
                                      <!-- <div class="col-sm">
                                        <div class="form-group mb-2">
                                          <label>Display Order</label>
                                          <input type="text" class="form-control hide" formControlName="displayOrder" placeholder="Enter display order" />
                                        </div>
                                      </div> -->
                                      <div class="col-sm">
                                        <div class="form-group form-check mb-2">
                                          <input type="checkbox" class="form-check-input" formControlName="activeFlag" />
                                          <label class="form-check-label">&nbsp;Is Active Category</label>
                                        </div>
                                      </div>
                                      <div class="col-sm">
                                        <div class="text-right mb-2">
                                          <button class="btn btn-default" (click)="toggleEdit(c)"><i class="fa fa-remove"></i>&nbsp;Cancel</button>
                                          <button class="btn btn-success" type="button" (click)="updateCategory()"><i class="fa fa-check"></i>&nbsp;Update</button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>
                              <input type="text" class="form-control" [(ngModel)]="newCategory.name" placeholder="Enter subcategory name" />
                            </td>
                            <!-- <td>
                              <input type="text" class="form-control" [(ngModel)]="newCategory.displayOrder" placeholder="Enter display order" />
                            </td> -->
                            <td>
                              <button class="btn btn-success" type="button" (click)="saveNewCategory()"><i class="fa fa-plus"></i>&nbsp;Add</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          
        </div> <!-- end card-body -->
      </div>
    </div>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>