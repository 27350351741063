

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

import { BillTo } from './../../models/bill-to.model';
import { Country } from './../../models/country.model';
import { StateProvince } from './../../models/state-province.model';

import { OrderService } from './../../services/site/order.service';
import { LoggerService } from './../../services/utility/logger.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../services/site/user.service';
import { Observable } from 'rxjs';
import { textInputValidator } from 'src/app/shared/validators/allowedInput.validator';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BillingComponent implements OnInit {

  billToForm: FormGroup;
  billTo: BillTo;
  error = '';
  submitted = false;
  countries$: Observable<Country[]>;
  states$: Observable<StateProvince[]>;
  errors = '';

  constructor(
    private formBuilder: FormBuilder,
    private readonly orderService: OrderService,
    private readonly loggerService: LoggerService,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.getCountries();
    this.getStates();
    this.billToForm = this.createBillToForm();
    this.getBillTo();

  }

  get f() { return this.billToForm.controls; }

  createBillToForm() {
    return this.formBuilder.group({
      companyName: ['', Validators.maxLength(50)],
      firstName: ['', Validators.maxLength(50)],
      lastName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      address1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      aptNumber: ['', Validators.maxLength(50)],
      address2: ['', Validators.maxLength(50)],
      city: ['', [Validators.required, Validators.maxLength(50)]],
      stateCode: ['null', Validators.required],
      postalCode: ['', [ Validators.required, Validators.minLength(5), Validators.maxLength(5) ]],
      countryCode: ['null'],
      phoneNumber: [''],
      emailAddress: ['', Validators.email]
    });
  }

  setBillToForm(s: BillTo) {
    this.billToForm.setValue({
      companyName: s.companyName ?? '',
      firstName: s.firstName ?? '',
      lastName: s.lastName ?? '',
      address1: s.address1 ?? '',
      aptNumber: s.aptNumber ?? '',
      address2: s.address2 ?? '',
      city: s.city ?? '',
      postalCode: s.postalCode ?? '',
      stateCode: s.stateCode ?? null,
      countryCode: s.countryCode ?? null,
      emailAddress: s.emailAddress ?? '',
      phoneNumber: s.phoneNumber ?? ''
    });
  }

  getBillTo() {
    const savedBillTo = sessionStorage.getItem('billTo');
    if (savedBillTo === null || savedBillTo === undefined) {
      this.billTo = new BillTo();
    } else {
      this.billTo = JSON.parse(savedBillTo);
    }
    this.setBillToForm(this.billTo);
  }

  createBillTo() {
    return new BillTo({
      billToId: 0,
      companyName: this.f.companyName.value,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      address1: this.f.address1.value,
      aptNumber: this.f.aptNumber.value,
      address2: this.f.address2.value,
      city: this.f.city.value,
      stateCode: this.f.stateCode.value,
      postalCode: this.f.postalCode.value,
      countryCode: this.f.countryCode.value,
      phoneNumber: this.f.phoneNumber.value,
      emailAddress: this.f.emailAddress.value
    });
  }

  submit() {
    this.submitted = true;

    if (this.billToForm.invalid) {
      this.toastr.error('All required fields are not completed');
      return;
    }

    this.userService.checkPostalCode(this.f.postalCode.value, this.f.stateCode.value)
      .subscribe(res => {
        if (!res.validated) {
          this.toastr.error(res.errors[0]);
          return;
        }
        else {
          const billTo = this.createBillTo();

          // save to sessionStorage
          sessionStorage.setItem('billTo', JSON.stringify(billTo));

          this.router.navigate(['/orders/shipTo']);
        }
      });
  }

  getCountries() {
    const countries$ = this.userService.getCountries();
    this.countries$ = countries$;
  }

  getStates() {
    const states$ = this.userService.getStates();
    this.states$ = states$;
  }


}
