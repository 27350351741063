import { UserService } from './../../services/site/user.service';
import { CelebrationRequest } from './../../models/celebration.model';
import { Country } from './../../models/country.model';
import { StateProvince } from './../../models/state-province.model';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from './../../../environments/environment';

import { Notification } from './../../models/notification.model';
import { EmailAddress } from './../../models/email-address.model';

import { NotificationService } from './../../services/site/notification.service';
import { LoggerService } from './../../services/utility/logger.service';
import { ToastrService } from 'ngx-toastr';

export interface InstructorKit {
  id: string;
  name: string;
}

@Component({
  selector: 'app-celebration',
  templateUrl: './celebration.component.html',
  styleUrls: ['./celebration.component.scss']
})
export class CelebrationComponent implements OnInit {

  request: CelebrationRequest;
  requestForm: FormGroup;
  countries$: Observable<Country[]>;
  states$: Observable<StateProvince[]>;
  kits: InstructorKit[] = [];
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private readonly loggerService: LoggerService,
    private readonly notificationService: NotificationService,
    private readonly toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getCountries();
    this.getStates();
    this.getKits();
    this.requestForm = this.createRequestForm();
  }

  get f() { return this.requestForm.controls; }

  createRequestForm() {
    return this.formBuilder.group({
      bCompanyName: ['', Validators.maxLength(50)],
      bFirstName: ['', Validators.maxLength(50)],
      bLastName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      bAddress1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      bAddress2: ['', Validators.maxLength(50)],
      bCity: ['', [Validators.required, Validators.maxLength(50)]],
      bStateCode: ['null', Validators.required],
      bPostalCode: ['', [ Validators.required, Validators.minLength(5), Validators.maxLength(5) ]],
      bPhoneNumber: [''],
      bEmailAddress: ['', Validators.email],
      sCompanyName: ['', Validators.maxLength(50)],
      sFirstName: ['', Validators.maxLength(50)],
      sLastName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      sAddress1: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      sAddress2: ['', Validators.maxLength(50)],
      sCity: ['', [Validators.required, Validators.maxLength(50)]],
      sStateCode: ['null', Validators.required],
      sPostalCode: ['', [ Validators.required, Validators.minLength(5), Validators.maxLength(5) ]],
      sPhoneNumber: [''],
      sEmailAddress: ['', Validators.email],
      selectedKit: ['null', Validators.required]
    }) ;
  }

  getCountries() {
    const countries$ = this.userService.getCountries();
    this.countries$ = countries$;
  }

  getStates() {
    const states$ = this.userService.getStates();
    this.states$ = states$;
  }

  getKits() {
    this.kits.push({ id: 'Fiber Samples Packet', name: 'Fiber Samples Packet'})
    this.kits.push({ id: 'Yarn Samples Packet', name: 'Yarn Samples Packet' });
    this.kits.push({ id: 'Seams Kit', name: 'Seams Kit' });
    this.kits.push({ id: 'Trims Kit (Upholstery/Drapery)', name: 'Trims Kit (Upholstery/Drapery)' });
    this.kits.push({ id: 'Narrows Kit', name: 'Narrows Kit' })
  }

  sameAsBilling() {

  }

  submit() {
    this.submitted = true;

    if (this.requestForm.invalid) {
      return;
    }

    const notification = this.createNotification();

    this.notificationService.SendNotification(notification)
      .subscribe(data => {
        this.requestForm = this.createRequestForm();
        this.toastrService.success('Successfully sent celebration gift request to TextileFabric Consultants');
      });
  }

  createNotification() {
    const fromAddress = new EmailAddress({
      emailAddress: this.f.sEmailAddress.value,
      fullName: this.f.sFirstName.value + ' ' + this.f.sLastName
    });
    const toAddress = new EmailAddress({
      emailAddress: environment.fromAddress,
      fullName: '40 Year Celebration Form'
    });
    const billToAddress = `<b>BillTo Address:</b><br/>Company Name; ${this.f.bCompanyName.value}<br/>Name: ${this.f.bFirstName.value} ${this.f.bLastName.value}<br/>Address1: ${this.f.bAddress1.value}<br/>Address2: ${this.f.bAddress2.value}<br/>City: ${this.f.bCity.value}, ${this.f.bStateCode.value}  ${this.f.bPostalCode.value}<br/>Email: ${this.f.bEmailAddress.value}<br/>Phone: ${this.f.bPhoneNumber.value}<br/><br/>`;
    const shipToAddress = `<b>ShipTo Address:</b><br/>Company Name; ${this.f.sCompanyName.value}<br/>Name: ${this.f.sFirstName.value} ${this.f.sLastName.value}<br/>Address1: ${this.f.sAddress1.value}<br/>Address2: ${this.f.sAddress2.value}<br/>City: ${this.f.sCity.value}, ${this.f.sStateCode.value}  ${this.f.sPostalCode.value}<br/>Email: ${this.f.sEmailAddress.value}<br/>Phone: ${this.f.sPhoneNumber.value}<br/><br/>`;


    const body = billToAddress + shipToAddress + '<b>Selected Free Product:</b> ' + this.f.selectedKit.value;

    return new Notification({
      fromAddress,
      toAddress,
      subject: '40 Year Celebration Form',
      body
    });
  }

}
