import { Address } from './address.model';

export class UserProfile {
  companyName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  billTo: Address;
  shipTo: Address;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
