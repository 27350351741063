import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';

import { LoggerService } from './logger.service';

@Injectable()
export class ErrorService {

  constructor(
    private logger: LoggerService
  ) { }

  handleError(error: any) {

    // if (error.error instanceof ErrorEvent) {
    //   // A client-side or network error occurred. Handle it accordingly.
    //   this.logger.error(`Client: An error occurred: ${error.error.message}`);
    //   errorMessage = `Error: ${error.error.message}`;
    // } else {
    //   if (error !== undefined && error.error !== undefined && error.error.hasErrors !== undefined && error.error.hasErrors) {
    //     this.logger.error(`Server: Backend returned code ${error.status}, body was:  ${error.error.errors[0]}`);
    //     errorMessage = `Error: ${error.error.errors[0]}`;
    //   } else {
    //     // The backend returned an unsuccessful response code.
    //     // The response body may contain clues as to what went wrong,
    //     this.logger.error(`Server: Backend returned code ${error.status}, body was: ${error.error}`);
    //     errorMessage = `Error: ${error.error} (${error.status})`;
    //   }
    // }
    console.log('error: ' + JSON.stringify(error));
    // errorMessage = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    // if (error.error && error.error.hasErrors) {
    //console.log('error1: ' + error.error.errors);
    const errorMessage = `${error.error.errors}`;
    // }
    // return an ErrorObservable with a user-facing error message
    return throwError(errorMessage);
  }

}
