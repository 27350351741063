<div class="login-container">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-4">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Textile Fabric Consultants Login</h5>
          <form [formGroup]="loginForm" (ngSubmit)="submit()">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Enter email address" aria-label="EmailAddress" aria-describedby="basic-addon1" formControlName="email" />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon2"><i class="fa fa-lock"></i></span>
              </div>
              <input type="{{ isPasswordShown ? 'text' : 'password' }}" class="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="basic-addon2" formControlName="password" />
              <div class="input-group-append">
                <span class="input-group-text" (click)="togglePasswordShow()"><i class="fa {{ isPasswordShown ? 'fa-eye-slash' : 'fa-eye' }}"></i></span>
              </div>
            </div>
            <p *ngIf="error" class="error">
              {{ error }}
            </p>
            <button class="btn btn-success btn-block" type="submit">Login</button>
        </form>
        <div class="card-footer">
          <div class="row">
            <div class="col-sm text-center">
              <button class="btn btn-success" routerLink="/registration">Not a member? Sign Up</button>
            </div>
            <div class="col-sm text-center">
              <button class="btn btn-danger" routerLink="/resetpassword">Forgot Password?</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
