<div class="container orders-container">
  <div class="row">
    <div class="col-12 title-bar">
      <h3>Current Orders</h3>
      <p>Below is a list of current orders. Click on the View button to view the selected order.</p>
    </div>
    <div class="col-sm-12">
      <div class="order-status pull-right">
        <select class="form-control" [(ngModel)]="selectedStatus" (ngModelChange)="selectOrderStatus($event)">
          <option *ngFor="let s of statuses" [value]="s.name">{{ s.name }}</option>
        </select>
      </div>
    </div>
    <div class="col-12">
      <table class="table table-bordered table-condensed table-striped">
        <thead>
          <tr>
            <th>Order #</th>
            <th>Customer</th>
            <th>Order Date</th>
            <th>Total</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let o of orders">
            <td class="text-center">{{ o.orderID }}</td>
            <td>{{ o.customerName }}</td>
            <td>{{ o.orderDate | date:'MM/dd/yyyy' }}</td>
            <td>{{ o.orderTotal | currency }}</td>
            <td>{{ o.status }}</td>
            <td>
              <button class="btn btn-success" [routerLink]="['/admin/details', o.orderID]"><i class="fa fa-check"></i> View</button>
              <!-- <button class="btn btn-danger"><i class="fa fa-remove"></i> Delete</button> -->
            </td>
          </tr>
          <tr *ngIf="orders.length === 0">
            <td colspan="6">
              No orders found in database
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
