import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
//import { DataTableDirective } from 'angular-datatables';

import { AdminUser } from './../../../models/admin-user.model';

import { AdminService } from './../../../services/site/admin.service';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { TokenService } from './../../../services/auth/token.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  users: AdminUser[];
  pageNumber: number = 1;
  pageSize: number = 10;
  first: number = 1;
  rows: number = 10;
  totalRecords: number = 0;
  searchText: string = '';
  //dtOptions: DataTables.Settings = {};
  //dtTrigger: Subject<any> = new Subject();

  //@ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;

  constructor(
    private readonly adminService: AdminService,
    private readonly loadingService: LoadingService,
    private readonly tokenService: TokenService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.tokenService.isAdmin()) {
      this.router.navigate(['/home']);
    }
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 25
    // };
    this.getPaginatedUsers(this.pageNumber, this.pageSize);
  }

  ngOnDestroy(): void {
    //this.dtTrigger.unsubscribe();
    console.log('onDestroy');
  }

  setupDataTableOptions(): void {
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   pageLength: 10
    // };
  }

  getUsers(): void {
    this.adminService.getUsers()
      .subscribe(data => {
        this.users = data;
        //this.dtTrigger.next(this.dtTrigger);
      });
    //this.dtTrigger.next(this.dtTrigger);
  }

  getPaginatedUsers(pageNumber: number, pageSize: number): void {
    this.adminService.getPaginatedUsers(pageNumber, pageSize)
      .subscribe(data => {
        this.totalRecords = data.totalRecords;
        this.users = data.users;
      });
  }

  searchUsers(): void {
    console.log('searchUsers');
    this.adminService.searchPaginatedUsers(this.pageNumber, this.pageSize, this.searchText)
      .subscribe(data => {
        this.totalRecords = data.totalRecords;
        this.users = data.users;
      });
  }

  onPageChange($event) {
    console.log('event: ' + JSON.stringify($event));
    this.pageNumber = $event.page + 1;
    this.pageSize = $event.rows;
    if (this.searchText.length > 0) {
      this.searchUsers();
    } else {
      this.getPaginatedUsers(this.pageNumber, this.pageSize);
    }
  }

}
