import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ChildActivationEnd } from '@angular/router';

import { SlugifyPipe } from './../../../shared/pipes/slugify.pipe';
import { environment } from './../../../../environments/environment';

import { Category } from './../../../models/category.model';
import { Subcategory } from './../../../models/subcategory.model';
import { AdminCategory } from './../../../models/admin-category.model';
import { CategoryService } from './../../../services/site/category.service';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.scss'],
  providers: [SlugifyPipe]
})
export class SubcategoriesComponent implements OnInit {

  categories: Category[];
  parent: AdminCategory;
  showImages = false;

  imageSource = `/assets/images`;

  constructor(
    private slugify: SlugifyPipe,
    private readonly categoryService: CategoryService,
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    //this.parent = { id: 0, name: '', imagePath: '', slug: '', children: [], parent: null };
    this.route.params.subscribe(params => {
      console.log('id: ' + params.id);
      this.getParentCategory(params.id);
      this.getSubcategories(params.id);
    });
  }

  getSubcategories(parentId: number) {
    this.categoryService.getSubcategories(parentId)
      .subscribe(data => {
        this.categories = data;
      });
  }

  getParentCategory(parentId: number) {
    this.categoryService.getCategory(parentId)
      .subscribe(data => {
        this.parent = data;
      });
  }

}
