import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from './../../../../environments/environment';

import { ShoppingCartService } from './../../../services/site/shopping-cart.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() product;
  productForm: FormGroup;
  submitted = false;
  loading = false;
  error = '';
  imageSource = `${environment.awsUrl}/products`;

  constructor(
    private formBuilder: FormBuilder,
    private shoppingCartService: ShoppingCartService
  ) { }

  ngOnInit(): void {
    this.productForm = this.createProductForm();
  }

  get f() { return this.productForm.controls; }

  createProductForm() {
    return  this.formBuilder.group({
      quantity: ['1', [Validators.required, Validators.pattern('(0?[1-9]|[1-9][0-9]|[1][1-9][1-9]|200)')]]
    });
  }

  submit() {
    this.submitted = true;

    if (this.productForm.invalid) {
      return;
    }
    console.log('product: ' + JSON.stringify(this.product));
    this.shoppingCartService.addItem(this.product.id, this.product.name,
      this.f.quantity.value, this.product.unitPrice, this.product.weight,
      this.product.noShippingFlag);

  }

}
