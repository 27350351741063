<div class="container products-container">
  <div class="row">
      <div class="col-sm-12 admin-instructions title-bar">
          <h3>Textilefabric Products</h3>
          <p>Below is a list of products.  Click on the View button to view the selected product.</p>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <span>Select Category:&nbsp;</span>
              <p-dropdown 
              [options]="categories" 
              [(ngModel)]="selectedCategory"
              (onChange)="selectCategory($event)"
              optionLabel="name" 
              placeholder="Select Category" />
            </div>
            <div class="col-sm-6">
              <div *ngIf="displaySubcategories">
                <span>Select Subcategory:&nbsp;</span>
                <p-dropdown 
                  [options]="subcategories" 
                  [(ngModel)]="selectedSubcategory" 
                  (onChange)="selectSubcategory($event)"
                  optionLabel="name" 
                  placeholder="Select Subcategory" />
                </div>
            </div>
            <div class="col-sm-2 text-right">
              <button class="btn btn-primary" [routerLink]="['/admin/products', 0]"><i class="fa fa-plus"></i>&nbsp;Add Product</button>
            </div>
          
            <div class="col-sm-12">
              <div class="content-area">

                  <p-table [value]="products" [tableStyle]="{ 'min-width': '50rem' }">
                    <ng-template pTemplate="header">
                        <tr>
                          <th>Name</th>
                          <th>Image</th>
                          <th>Active</th>
                          <th>No Shipping Flag</th>
                          <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-p>
                        <tr>
                          <td>{{ p.name }}</td>
                        <td><img src="{{ imageSource }}/{{ p.imagePath }}" width="75" /></td>
                        <td>{{ p.activeFlag }}</td>
                        <td>{{ p.noShippingFlag }}</td>
                        <td>
                          <button class="btn btn-success" [routerLink]="['/admin/products', p.id]"><i class="fa fa-check"></i>&nbsp;View</button>
                        </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                      <div class="flex align-items-center justify-content-between">
                          Total Products: {{products ? products.length : 0 }}
                      </div>
                  </ng-template>
                </p-table>
                
              </div>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
