
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminOrder } from '../../../models/admin-order.model';
import { AdminUser } from './../../../models/admin-user.model';
import { Category } from './../../../models/category.model';
import { Product } from './../../../models/product.model';
import { Media } from '../../../models/media.model';
import { AdminService } from './../../../services/site/admin.service';
import { CategoryService } from './../../../services/site/category.service';
import { TokenService } from './../../../services/auth/token.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  orders$: Observable<AdminOrder[]>;
  users$: Observable<AdminUser[]>;
  products$: Observable<Product[]>;
  categories$: Observable<Category[]>;
  videos$: Observable<Media[]>;

  constructor(
    private readonly adminService: AdminService,
    private readonly categoryService: CategoryService,
    private readonly tokenService: TokenService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.canViewAdmin()) {
      this.getOrders('Ordered');
      this.getUsers();
      this.getProducts();
      this.getCategories();
      this.getVideos();
    }
    else {
      this.router.navigate(['/home']);
    }
  }

  canViewAdmin() {
    return this.tokenService.isAdmin();
  }

  getOrders(statusCode: string) {
    this.orders$ = this.adminService.getOrders(statusCode);
  }

  getUsers(): void {
    this.users$ = this.adminService.getUsers();
  }

  getProducts(): void {
    this.products$ = this.adminService.getProducts();
  }

  getCategories(): void {
    this.categories$ = this.categoryService.getCategories();
  }

  getVideos(): void {
    this.videos$ = this.adminService.listMedias();
  }

}
