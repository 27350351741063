export class CartItem {
  productId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  itemTotal: number;
  weight: number;
  noShippingFlag: boolean;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
