
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ConfirmOrder } from './../../models/confirm-order.model';
import { ConfirmBillTo } from './../../models/confirm-bill-to.model';

import { SessionOrderService } from './../../services/site/session-order.service';
import { LoggerService } from './../../services/utility/logger.service';
import { ShoppingCartService } from './../../services/site/shopping-cart.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../services/site/user.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  finalOrder: ConfirmOrder;
  showTotal = false;
  loading = true;

  constructor(
    private readonly sessionOrderService: SessionOrderService,
    private readonly loggerService: LoggerService,
    private readonly router: Router,
    private readonly shoppingCartService: ShoppingCartService,
    private readonly toastr: ToastrService,
    private readonly userService: UserService
  ) { }

  ngOnInit(): void {
    this.checkRedirectUrl();
    this.initFinalOrder();
    this.calculateOrder();
  }

  calculateOrder() {
    const sessionOrder = this.sessionOrderService.getSessionOrder(0);
    console.log('confirmation-sessionOrder: ' + JSON.stringify(sessionOrder));
    this.userService.calculateOrderTotals(sessionOrder)
      .subscribe(data => {
        // console.log('finalOrder: ' + JSON.stringify(data));
        if (data.hasErrors) {
          this.toastr.error('Order Error: ' + data.errors[0]);
          return;
        }

        this.finalOrder = data.sessionOrder;
        this.finalOrder.billTo.fullAddress = this.getBillToFullAddress(this.finalOrder);
        this.finalOrder.shipTo.fullAddress = this.getShipToFullAddress(this.finalOrder);
        this.showTotal = true;
        console.log('finalOrder: ' + JSON.stringify(this.finalOrder));
        this.loading = false;
      }, error => {
        console.log('calculateOrder Error: ' + error);
        this.loading = false;
      });
  }

  getBillToFullAddress(order: ConfirmOrder) {
    let fullAddress = `${order.billTo.address1} `;
    if (order.billTo.aptNumber.length > 0) {
      fullAddress += order.billTo.aptNumber;
    }
    fullAddress += '<br/>';
    if (order.billTo.address2.length > 0) {
      fullAddress += order.billTo.address2 + '<br/>';
    }
    return fullAddress;
  }

  getShipToFullAddress(order: ConfirmOrder) {
    let fullAddress = `${order.shipTo.address1} `;
    if (order.shipTo.aptNumber.length > 0) {
      fullAddress += order.shipTo.aptNumber;
    }
    fullAddress += '<br/>';
    if (order.shipTo.address2.length > 0) {
      fullAddress += order.shipTo.address2 + '<br/>';
    }
    return fullAddress;
  }

  checkRedirectUrl() {
    const redirectUrl = sessionStorage.getItem('redirectUrl');
    if (redirectUrl != null && redirectUrl !== undefined) {
      sessionStorage.removeItem('redirectUrl');
    }
  }

  initFinalOrder(): void {
    const billTo = new ConfirmBillTo({ companyName: '', firstName: '', lastName: '' });
    this.finalOrder = new ConfirmOrder();
    this.finalOrder.billTo = billTo;
    this.finalOrder.lineItems = [];
  }

}
