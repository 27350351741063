import { CartItem } from './cart-item.model';

export class OrderShipping {
  totalWeight: number;
  sourcePostalCode: string;
  destPostalCode: string;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
