import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { PasswordReset } from './../../models/password-reset.model';
import { PasswordChange } from './../../models/password-change.model';

import { ToastrService } from 'ngx-toastr';
import { UserService } from './../../services/site/user.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-checkresetpassword',
  templateUrl: './checkresetpassword.component.html',
  styleUrls: ['./checkresetpassword.component.scss']
})
export class CheckresetpasswordComponent implements OnInit {

  token: string = "";
  emailAddress: string = "";
  pwdResetForm: FormGroup;
  passwordChange: PasswordChange;
  submitted = false;
  isValidToken = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private readonly toastrService: ToastrService,
    private userService: UserService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    console.log('checkresetpasssword init');
    this.route.params.subscribe(params => {
      console.log('params: ' + JSON.stringify(params));
      this.token = params.token;
      console.log('checkresetpassword token: ' + this.token);
      this.lookupToken(this.token);
    });

    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
    
    if (this.token.length > 0) {
      this.isValidToken = true;
      this.createPasswordResetForm();
    } else {
      this.toastrService.error("Invalid Token");
    }
  }

  get f() { return this.pwdResetForm.controls; }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('passwordConfirm').value
       ? null : {'mismatch': true};
  }

  createPasswordResetForm() {
    this.pwdResetForm = this.fb.group({
      password1: ['', [Validators.required, Validators.minLength(8)]],
      password2: ['', [Validators.required, Validators.minLength(8)]]
    }, this.passwordMatchValidator);
  }

  getFormValues() {
    return new PasswordChange({
      emailAddress: this.emailAddress,
      newPassword: this.f.password1.value
    });
  }

  lookupToken(token: string) {
    this.userService.lookupToken(token)
      .subscribe(data => {
        console.log('lookupToken-data: ' + JSON.stringify(data));
        this.isValidToken = data.isSuccessful ? true : false;
        this.emailAddress = data.emailAddress;
      }, error => {
        console.log('lookupToken-error: ' + JSON.stringify(error));
        this.isValidToken = false;
      });
  }

  submit() {
    this.submitted = true;

    if (!this.pwdResetForm.valid) {
      this.toastrService.error('Password Reset Form is invalid');
      return;
    }

    this.spinner.show();
    
    this.userService.updatePassword(this.emailAddress, this.f.password1.value)
      .subscribe(data => {
        console.log('pwd_reset-data: ' + JSON.stringify(data));
        this.spinner.hide();
        if (data.isSuccessful) {
          this.toastrService.success('Successfully updated user password');
          this.router.navigate(['/login']);
        } else {
          this.toastrService.error("Password could not be reset");
        }
        
      });
  }

}
