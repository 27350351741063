
import { BillTo } from './../models/bill-to.model';
import { ShipTo } from './../models/ship-to.model';
import { OrderStatus } from './../models/order-status.model';
import { LineItem } from './../models/line-item.model';
import { Transaction } from './../models/transaction.model';
export class Order {
  orderID: number;
  subTotal: number;
  salesTax: number;
  shippingAmount: number;
  orderTotal: number;
  orderStatus: OrderStatus;
  dateCreated: Date;
  dateUpdated: Date;
  userId: number;
  accountId: number;
  billTo: BillTo;
  shipTo: ShipTo;
  lineItems: LineItem[];
  transactions: Transaction[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}
