import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private jwtHelper: JwtHelperService,
    private router: Router)
    {

    }

    canActivate() {
      // get the jwt token which are present in the session storage
      const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

      // Check if the token is expired or not and if token is expired then redirect to login page and return false
      if (currentUser !== null && currentUser.token && !this.jwtHelper.isTokenExpired(currentUser.token)) {
        return true;
      }
      this.router.navigate(['/login']);
      return false;
    }
}
